var next_prev_event_register;

import $ from 'jquery';

import 'materialize-css/dist/js/materialize';

require('webpack-jquery-ui/sortable');

import 'kbw-countdown/dist/js/jquery.plugin';

import 'kbw-countdown/dist/js/jquery.countdown';

next_prev_event_register = function() {
  $(".next-pg").on("click", function() {
    var next_element;
    next_element = $(this);
    return $.ajax({
      url: next_element.data("view-url"),
      data: {
        sequence_number: next_element.data("id"),
        display: next_element.data("display")
      },
      success: function(data) {
        $(".info-content").html(data);
        $('.info-content').find('a.modal-close').click(function() {
          return $('.info-content').siblings('button.modal-close').click();
        });
        next_prev_event_register();
        return rounds_ready();
      },
      error: function(data) {
        return $('#open-briefing.modal').modal('close');
      }
    });
  });
  return $(".prev-pg").on("click", function() {
    var prev_element;
    prev_element = $(this);
    return $.ajax({
      url: prev_element.data("view-url"),
      data: {
        sequence_number: prev_element.data("id"),
        display: prev_element.data("display")
      },
      success: function(data) {
        $(".info-content").html(data);
        $('.info-content').find('a.modal-close').click(function() {
          return $('.info-content').siblings('button.modal-close').click();
        });
        next_prev_event_register();
        return rounds_ready();
      },
      error: function(data) {
        return $('#open-briefing.modal').modal('close');
      }
    });
  });
};

$(function() {
  var drop_element, start_timers;
  $(".modal-close").click(function() {
    return $(this).parents('.modal.open').modal('close');
  });
  $(".modal-briefing").click(function() {
    var current_seq_number, element, view_url;
    current_seq_number = $(document).find('#round_page_sequence_number').val();
    view_url = $(document).find('.fetch-page-details').first().data("view-url");
    element = $(this);
    return $.ajax({
      url: view_url,
      data: {
        sequence_number: current_seq_number,
        page_click: true
      },
      success: function(result) {
        var confirmation_variable;
        if (result.status !== true) {
          $('.brief-page-update').removeClass('active-page');
          $('.brief-page-update').first().addClass('active-page');
          return element.parents('.modal.open').modal('close');
        } else {
          confirmation_variable = confirm("Do you want to leave this briefing page without saving ?");
          if (confirmation_variable === true) {
            return element.parents('.modal.open').modal('close');
          }
        }
      }
    });
  });
  start_timers = function() {
    return $('.time-disposeable').each(function() {
      var diff, newtime;
      newtime = new Date(parseFloat($(this).data('time')));
      diff = (newtime - new Date()) / 1000;
      if (diff >= 259200) {
        return $(this).countdown({
          until: diff,
          compact: true,
          layout: '<b>{dn}d {hnn}h {mnn}m</b> {desc}',
          format: 'dHM',
          description: ''
        });
      } else {
        return $(this).countdown({
          until: diff,
          compact: true,
          layout: '<b>{hnn}h {mnn}m {snn}s</b> {desc}',
          format: 'HMS',
          description: ''
        });
      }
    });
  };
  $('.sort-abel-content').sortable({
    start: function(event, ui) {
      ui.item.data("startindex", ui.item.index());
      return console.log(ui.item.index());
    },
    update: function(event, ui) {
      var new_index, old_index, replaced_id, replaced_object, replacing_id, replacing_object;
      new_index = ui.item.index();
      old_index = ui.item.data("startindex");
      replacing_object = $(this).find('.round-summary').get(new_index);
      replaced_object = new_index > 0 ? $(this).find('.round-summary').get(new_index - 1) : null;
      replacing_id = $(replacing_object).data('id');
      replaced_id = $(replaced_object).data('id');
      console.log(replacing_id + ', ' + replaced_id);
      return $.ajax({
        type: 'POST',
        url: $(replacing_object).data('move-url'),
        data: {
          move_params: {
            replacing_id: replacing_id,
            replaced_id: replaced_id,
            new_index: new_index,
            old_index: old_index
          }
        },
        success: function() {}
      });
    }
  });
  $('.sort-abel-content').disableSelection();
  $('.droppable').each(function() {
    var element;
    element = $(this);
    return element.droppable({
      accept: element.data("draggable"),
      classes: {
        "ui-droppable-active": element.data('active-class')
      },
      drop: function(event, ui) {
        return drop_element($(this), event, ui);
      }
    });
  });
  $('.round-edit').on("click", function() {
    var element;
    element = $(this);
    return $.ajax({
      url: element.data("edit-url"),
      data: {},
      success: function(data) {
        $("#modal-round-edit").find('.round-edit-body').html(data);
        init_time_picker();
        $('select').material_select();
        $("#modal-round-edit").find($(".modal-close")).click(function() {
          return $("#modal-round-edit").modal('close');
        });
        break_rounds_ready();
        brief_rounds_ready();
        brainstorm_rounds_ready();
        return init_date_picker();
      }
    });
  });
  drop_element = function(element, event, ui) {
    var mission_id;
    if ($(ui.draggable.data('modal')).attr('id') === 'modal-brainstorming') {
      mission_id = $('#mission_id').val();
      $.ajax({
        type: 'POST',
        url: '/ideations/' + mission_id + '/brain_storm_rounds',
        data: {
          brain_storm_round: {
            title: 'Brainstorm',
            duration: '1',
            duration_hours: '00:00',
            score_type: 0
          }
        },
        success: function(data) {
          $("#modal-round-edit").modal('open');
          $("#modal-round-edit").find('.round-edit-body').html(data);
          break_rounds_ready();
          brief_rounds_ready();
          brainstorm_rounds_ready();
          init_time_picker();
          $('select').material_select();
          return $("#modal-round-edit").find($(".modal-close")).click(function() {
            return $("#modal-round-edit").modal('close');
          });
        }
      });
    } else {
      $(ui.draggable.data('modal')).modal('open');
    }
    return setTimeout(function() {
      break_rounds_ready();
      brief_rounds_ready();
      return brainstorm_rounds_ready();
    }, 100);
  };
  $('.open-objective-leader-board').on("click", function() {
    var element;
    element = $(this);
    return $.ajax({
      url: element.data("url"),
      data: {},
      success: function(data) {
        $("#objective-leaderboard-review").html(data);
        $("#objective-leaderboard-review").find($('ul.tabs')).tabs();
        $("#objective-leaderboard-review").find($('.scroller').not('.slick-initialized')).slick({
          dots: true
        });
        return $("#ideas-tab-button").on("click", function() {
          return $("#objective-leaderboard-review").find($('.scroller').not('.slick-initialized')).slick({
            dots: true
          });
        });
      }
    });
  });
  $('.open-mission-leader-board').on("click", function() {
    var element;
    element = $(this);
    return $.ajax({
      url: element.data("url"),
      data: {},
      success: function(data) {
        $("#mission-leaderboard.modal").html(data);
        $("#mission-leaderboard.modal").find($('ul.tabs')).tabs();
        return $("#mission-leaderboard.modal").find($(".modal-close")).click(function() {
          return $("#mission-leaderboard").modal('close');
        });
      }
    });
  });
  return $('.open-info').on("click", function() {
    var element;
    $('.time-disposeable').countdown('destroy');
    element = $(this);
    return $.ajax({
      url: element.data("url"),
      data: {},
      success: function(data) {
        $(".info-content").html(data);
        $('.info-content').find('a.modal-close').click(function() {
          return $('.info-content').siblings('button.modal-close').click();
        });
        start_timers();
        return next_prev_event_register();
      },
      error: function(data) {
        return $('#open-briefing.modal').modal('close');
      }
    });
  });
});
