# Import TinyMCE
import tinymce from 'tinymce/tinymce'

# A theme is also required
import 'tinymce/themes/silver/theme'
import 'tinymce/themes/mobile/theme'
import 'tinymce/icons/default';
#require.context(
#  'file-loader?name=[path][name].[ext]&context=node_modules/tinymce!tinymce/skins/content/default',
#  true,
#  /.*/
#);
#require.context(
#  'file-loader?name=[path][name].[ext]&context=node_modules/tinymce!tinymce/skins/ui/oxide',
#  true,
#  /.*/
#);
# Any plugins you want to use has to be imported
import 'tinymce/plugins/paste'
import 'tinymce/plugins/link'
import 'tinymce/plugins/spellchecker'
import 'tinymce/plugins/table'
#import 'tinymce/plugins/textcolor'
import 'tinymce/plugins/wordcount'
import 'tinymce/plugins/autolink'
import 'tinymce/plugins/advlist'
import 'tinymce/plugins/image'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/charmap'
import 'tinymce/plugins/print'
import 'tinymce/plugins/preview'
import 'tinymce/plugins/code'
import 'tinymce/plugins/media'

# Initialize the app
export tinymce_settings = 
  selector: '.tinymce'
  #theme: 'mobile'
  skin: false
  width: 360
  content_style: 'p { font-family: sans-serif; margin:0; }'
  selector: '.tinymce',
  plugins: ['advlist', 'autolink', 'image', 'lists', 'charmap', 'print', 'code', 'media', 'paste', 'link', 'spellchecker', 'table', 'wordcount']
  selector: 'textarea.tinymce'
  convert_urls: false,
  image_class_list: [
    {
      title: 'Responsive'
      value: 'responsive-img'
    }
  ]
  menubar: false,
  paste_retain_style_properties: "",
  toolbar: 'undo redo bold italic link',
  target_list: [{title: 'New window', value: '_blank'}],
  setup: (editor)->
    editor.on('change', ->
      editor.save();
      title_elem = $($(editor.targetElm).data('preview-title'))
      title_elem.addClass("preview-h5")
      title_elem.text($(editor.targetElm).data('preview-title-text'))
      $($(editor.targetElm).data('preview-text')).html($(editor.targetElm).val())
      $('.end-note-text:visible').html( $('.ending_text').val())
    );

export {tinymce}