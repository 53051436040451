import {
  tinymce,
  tinymce_settings
} from '../shared/tinymce';

window.brief_round_image = function() {
  $(".brief-round-image:visible").off("change");
  return $(".brief-round-image:visible").on("change", function() {
    return Materialize.toast("Please save the round to preview the image.", 2000);
  });
};

window.brief_rounds_ready = function() {
  var new_brief_page;
  tinymce.init(tinymce_settings);
  $('.brief_round_duration_field:visible').on("change", function() {
    var date, time;
    date = new Date(parseFloat($(this).data('start-date')));
    $('.brief-start-date:visible').addClass("preview-h5");
    $('.brief-start-date:visible').text("Start date");
    $('.brief-start-date-area:visible').text(date_to_string(date));
    $('.brief-end-date-area:visible').text(date_to_string(get_end_date($('.brief_round_duration_field:visible').data('start-date'), $('.brief_round_duration_field:visible').data('duration'), $('.brief_round_duration_hours:visible').val())));
    $('.brief-duration-title').addClass("preview-h5");
    $('.brief-duration-title').text("Briefing Duration");
    time = $('.brief_round_duration_hours:visible').val().split(":");
    return $('.brief-duration-area:visible').text($('.brief_round_duration_field:visible').data('duration') + "D " + time[0] + "H " + time[1] + "M");
  });
  $('.brief_round_duration_hours:visible').on("change", function() {
    var time;
    time = $('.brief_round_duration_hours:visible').val().split(":");
    $('.brief-duration-area:visible').text($('.brief_round_duration_field:visible').data('duration') + "D " + time[0] + "H :" + time[1] + "M");
    return $('.brief-end-date-area:visible').text(date_to_string(get_end_date($('.brief_round_duration_field:visible').data('start-date'), $('.brief_round_duration_field:visible').data('duration'), $('.brief_round_duration_hours:visible').val())));
  });
  $('.brief_round_brief_text:visible').on("input", function() {
    return $('.brief-text-preview-area:visible').text($('.brief_round_brief_text:visible').val());
  });
  $('.brief_round_duration_field:visible').trigger('change');
  $('.brief_round_duration_hours:visible').trigger('change');
  $('.brief_round_brief_text:visible').trigger('input');
  $('#modal-empty-brief').off("click");
  $('#modal-empty-brief').on("click", function(event) {
    $('#prompt-dialogbox').modal('open');
    return setTimeout(function() {
      $('#prompt-dialogbox').modal('close');
      return $('.lean-overlay').remove();
    }, 5000);
  });
  $('#close-modal-empty-brief').off("click");
  $('#close-modal-empty-brief').on("click", function(event) {
    $('#prompt-dialogbox').modal('close');
    return $('.lean-overlay').remove();
  });
  $('.open-pages-dialogue').on("click", function(event) {
    var element;
    event.preventDefault();
    $('.brief-page-update').removeClass('active-page');
    $('.brief-page-update').first().addClass('active-page');
    element = $(this);
    return $.ajax({
      url: element.data("view-url"),
      data: {},
      success: function(data) {
        var $toast_content, i, len, ref, round_id, single_url;
        $("#pages-view-modal").find(".pages-view-content").html(data);
        round_id = $('#round_info').val();
        ref = $('.fetch-page-details');
        for (i = 0, len = ref.length; i < len; i++) {
          single_url = ref[i];
          $(single_url).attr('data-view-url', "/missions/separate-pages/rounds/" + round_id + "/round_pages/show_sequence_number");
        }
        $toast_content = $("#status-messages");
        if ($toast_content.length !== 0 && $toast_content.html() !== "") {
          $("#status-messages").css("display", "block");
          Materialize.toast($toast_content, 1000000);
        }
        return $("#pages-description").modal('open');
      }
    });
  });
  new_brief_page = function(element) {
    $('.brief-page-update').removeClass('active-page');
    element.find('a').addClass('active-page');
    return $.ajax({
      url: element.attr("data-view-url"),
      data: {
        sequence_number: element.data("id")
      },
      success: function(data) {
        var $toast_content;
        $("#pages-view-modal").find(".pages-view-content").html(data);
        $toast_content = $("#status-messages");
        if ($toast_content.length !== 0 && $toast_content.html() !== "") {
          $("#status-messages").css("display", "block");
          Materialize.toast($toast_content, 1000000);
        }
        return $('.media_type').on("click", function() {
          var selected_element;
          selected_element = $(this).val();
          if (selected_element === "image") {
            $(".page-image").css("display", "block");
            return $(".page-video").css("display", "none");
          } else if (selected_element === "video") {
            $(".page-video").css("display", "block");
            return $(".page-image").css("display", "none");
          }
        });
      }
    });
  };
  $('.fetch-page-details').off("click");
  $('.fetch-page-details').on("click", function(event) {
    var approved, attachment_url, current_seq_number, description, element, embedded_video_code, image_url, seq_num;
    event.preventDefault();
    $("#status-messages").css("display", "none");
    $("#status-messages").html("");
    $("#status-messages").remove();
    element = $(this);
    current_seq_number = $(document).find('#round_page_sequence_number').val();
    seq_num = element.data("id");
    description = $('#round_page_description').val();
    attachment_url = $('#round_page_attachment_url').val();
    image_url = $('#round_page_image_url').val();
    embedded_video_code = $('#round_page_embedded_video_code').val();
    approved = $('.approve-check')[0].checked;
    return $.ajax({
      url: element.attr("data-view-url"),
      data: {
        sequence_number: current_seq_number,
        page_click: true,
        description: description,
        attachment_url: attachment_url,
        image_url: image_url,
        embedded_video_code: embedded_video_code,
        approved: approved
      },
      success: function(result) {
        var confirmation_variable, difference_check, round_page;
        difference_check = false;
        if (result.status !== true) {
          round_page = result.round_page;
          if (result.difference_check === true) {
            confirmation_variable = confirm("Do you want to leave this briefing page without saving ?");
            if (confirmation_variable === true) {
              return new_brief_page(element);
            }
          } else {
            return new_brief_page(element);
          }
        } else {
          confirmation_variable = confirm("Do you want to leave this briefing page without saving ?");
          if (confirmation_variable === true) {
            return new_brief_page(element);
          }
        }
      }
    });
  });
  $(".brief-round-show-advance-fields").on("click", function() {
    $(".brief-round-adv").hide();
    $(".brief-round-advance-fields").removeClass('hide');
    return brief_round_image();
  });
  $(".brief-round-hide-advance-fields").on("click", function() {
    $(".brief-round-advance-fields").addClass('hide');
    $(".brief-round-adv").show();
    return brief_round_image();
  });
  return brief_round_image();
};

$(document).ready(function() {
  return brief_rounds_ready;
});

$(document).on('ajax:complete', brief_rounds_ready);
