/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import $ from 'jquery';
import 'kbw-countdown/dist/js/jquery.plugin';
import 'kbw-countdown/dist/js/jquery.countdown';

export var start_timers = function start_timers() {
  return $('.time-disposeable').each(function() {
    var newtime = new Date(parseFloat($(this).data('time')));
    var diff = (newtime - new Date())/1000;
    if(diff >= 259200) { // 259200 = 3 days
      return $(this).countdown({until: diff, compact: true, layout: '<b>{dn}d {hnn}h {mnn}m</b> {desc}',format: 'dHM', description: '', onTick: watch_countdown});
    } else {
      return $(this).countdown({until: diff, compact: true, layout: '<b>{hnn}h {mnn}m {snn}s</b> {desc}', format:'HMS', description: '', onTick: watch_countdown});
    }
  });
};

export var watch_countdown = function watch_countdown(periods) {
    var new_layout = null;
    if (periods[3] > 0) {
      new_layout = '<b>{dn}d {hnn}h {mnn}m</b> {desc}';
    } else {
      new_layout = '<b>{hnn}h {mnn}m {snn}s</b> {desc}';
    }

    if (new_layout && ($(this).countdown('option','layout') !==new_layout)) {
      return $(this).countdown('option',{layout: new_layout});
    }
  };
export var disable_default_form_submit = function disable_default_form_submit() {
  $('.ajax-submit').on('submit', function(event) {
    event.preventDefault();
  });
};
