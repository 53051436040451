/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
// You can use CoffeeScript in this file: http://coffeescript.org/

window.invite_round_image = function() {
  $(".invite-round-image:visible").off("change");
  return $(".invite-round-image:visible").on("change", function() {
    return Materialize.toast("Please save the round to preview the image.", 2000);
  });
};
var invitation_rounds_ready = function invitation_rounds_ready() {

  //Preview of Invitation Round!!
  $('#invite_round_invite_text').on("input", function() {
    $('#invitation-text-preview').addClass("preview-h5");
    $('#invitation-text-preview').text("Mission Invitation");
    return $('.invitation-area').html($('#invite_round_invite_text').val().replace(/\r\n|\r|\n/g,"<br>"));
  });


  $('#invite_round_send_on').on("change", function() {
    $('#start-invitation-date').addClass("preview-h5");
    $('#start-invitation-date').text("Invitation Starts On");
    return $('.start-date-area').text($('#invite_round_send_on').val()+" "+ $('#invite_round_send_on_time').val());
  });


  $('#invite_round_end_date').on("change", function() {
    $('#end-invitation-date').addClass("preview-h5");
    $('#end-invitation-date').text("Invitation DeadLine");
    return $('.end-date-area').text($('#invite_round_end_date').val()+" "+ $('#invite_round_end_time').val());
  });


  $('#invite_round_send_on_time').on("change", function() {
    $('#start-invitation-date').addClass("preview-h5");
    $('#start-invitation-date').text("Invitation Starts On");
    return $('.start-date-area').text($('#invite_round_send_on').val()+" "+ $('#invite_round_send_on_time').val());
  });


  $('#invite_round_end_time').on("change", function() {
    $('#end-invitation-date').addClass("preview-h5");
    $('#end-invitation-date').text("Invitation DeadLine");
    return $('.end-date-area').text($('#invite_round_end_date').val()+" "+ $('#invite_round_end_time').val());
  });

  $(".invite-round-show-advance-fields").on("click", function() {
    $(".invite-round-adv").hide();
    $(".invite-round-advance-fields").removeClass('hide');
    return invite_round_image();
  });

  $(".invite-round-hide-advance-fields").on("click", function() {
    $(".invite-round-advance-fields").addClass('hide');
    $(".invite-round-adv").show();
    return invite_round_image();
  });

  $('#invite_round_invite_text').trigger('input');
  $('#invite_round_send_on').trigger('change');
  $('#invite_round_end_date').trigger('change');
  $('#invite_round_send_on_time').trigger('change');
  $('#invite_round_end_time').trigger('change');
  invite_round_image();
  
  $('.send-inbox-invites').off('click'); 
  return $('.send-inbox-invites').on('click', function() {
    if ($('.invi_text').val() === '') {
      $('.toast').remove();
      return Materialize.toast('Invitation text can not be empty.', 2000);
    } else {
      var selected_ids = $('#mission-table-Section1 input:checkbox:checked').length;
      var selected_agent =  selected_ids + " agent ";
      if (selected_ids  >= 1) { 
        selected_agent =  selected_ids + " agents ";
      }
      // if ((selected_ids  >= 1) && confirm("You've selected " + selected_agent + "in the AGENTS tab. Are you sure you want to send the invitation to this agent?")) {
        // selected_ids = $('#mission-table-Section1 input:checkbox:checked').map(function() {
        //   return $(this).val();
        //   });
      var form = $('.invitation_mission_form');
      var datastring = form.serialize();
      datastring = datastring + '&' + 'invite_ids' + '=' + '[' + [] + ']';      
      $.ajax({
        method: 'POST',
        url: form.attr('action'),
        data: datastring,
        dataType: 'script'
      });
      // } else { return confirm("You've selected " + $('#mission-table-Section1 input:checkbox:checked').length + " agent in the AGENTS tab. Are you sure you want to send the invitation to this agent?"); }
      $('#modal-edit-invite-text').modal('close')
    }
  });
};


$(document).ready(invitation_rounds_ready);
$(document).on('ajax:complete', invitation_rounds_ready);
