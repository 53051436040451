import {
  disable_default_form_submit
} from './common';

import $ from 'jquery';

import {
  tinymce,
  tinymce_settings
} from '../shared/tinymce';

window.brainstorm_round_image = function() {
  $(".brainstorm-round-image:visible").off("change");
  return $(".brainstorm-round-image:visible").on("change", function() {
    return Materialize.toast("Please save the round to preview the image.", 2000);
  });
};

window.brainstorm_rounds_ready = function() {
  var re_register_round_click;
  if ($('#brain_storm_round_add_categories').is(':checked')) {
    $(".ideation-categories-lists").show();
  } else {
    $(".ideation-categories-lists").hide();
  }
  $(document).on('click', '#brain_storm_round_add_categories', function(e) {
    if ($('#brain_storm_round_add_categories').is(':checked')) {
      return $(".ideation-categories-lists").show();
    } else {
      return $(".ideation-categories-lists").hide();
    }
  });
  if ($(document).find('.sortable').length > 0) {
    $(document).find('.sortable').railsSortable();
  }
  $('.delete-bs-category').off('click');
  $('.delete-bs-category').on('click', function(e) {
    var brain_storm_round_id, mission_id;
    if (confirm('Are you sure you want to delete this category?')) {
      mission_id = $('#mission_id').val();
      brain_storm_round_id = $('#brain_storm_round_id').val();
      return $.ajax({
        type: 'DELETE',
        url: '/brain_storm_categories/' + this.id,
        data: {
          brain_storm_round_id: brain_storm_round_id,
          mission_id: mission_id
        },
        success: function(data) {}
      });
    }
  });
  $(".add-bs-category").off('click');
  $(".add-bs-category").on('click', function() {
    var brain_storm_round_id, category_value, mission_id;
    mission_id = $('#mission_id').val();
    brain_storm_round_id = $('#brain_storm_round_id').val();
    category_value = $('#category-input').val();
    if (category_value !== '') {
      return $.ajax({
        type: 'POST',
        url: '/brain_storm_categories',
        data: {
          brain_storm_round_id: brain_storm_round_id,
          mission_id: mission_id,
          categories: {
            title: category_value
          }
        },
        success: function(data) {}
      });
    } else {
      return Materialize.toast('Enter category please', data["message"]``, 5000);
    }
  });
  $('.edit-bs-category').off('click');
  $('.edit-bs-category').on('click', function(e) {
    var brain_storm_round_id, mission_id;
    mission_id = $('#mission_id').val();
    brain_storm_round_id = $('#brain_storm_round_id').val();
    return $.ajax({
      type: 'GET',
      url: '/brain_storm_categories/' + this.id + '/edit',
      data: {
        brain_storm_round_id: brain_storm_round_id,
        mission_id: mission_id
      },
      success: function(data) {}
    });
  });
  tinymce.init(tinymce_settings);
  $('.brain_round_form').removeClass('disabled');
  $('#brain_storm_round_duration').on("change", function() {
    var date, time;
    if ($('.brain_storm_round_duration:visible').length > 0) {
      date = new Date(parseFloat($('.brain_storm_round_duration:visible').data('start-date')));
      $('.brainstorm-start-date-area:visible').text(date_to_string(date));
      $('.brainstorm-end-date-area:visible').text(date_to_string(get_end_date($('.brain_storm_round_duration:visible').data('start-date'), parseInt($(this).val()), $('.brain_storm_round_duration_hours:visible').val())));
      time = $('#brain_storm_round_duration_hours:visible').val().split(":");
      return $('.brainstorm-duration-area:visible').text($(this).val() + "D " + time[0] + "H " + time[1] + "M");
    }
  });
  $('.brain_storm_round_duration_hours:visible').on("change", function() {
    var time;
    time = $('.brain_storm_round_duration_hours:visible').val().split(":");
    $('.brainstorm-duration-area:visible').text($('#brain_storm_round_duration').val() + "D " + time[0] + "H :" + time[1] + "M");
    return $('.brainstorm-end-date-area:visible').text(date_to_string(get_end_date($('.brain_storm_round_duration:visible').data('start-date'), parseInt($('#brain_storm_round_duration').val()), $('.brain_storm_round_duration_hours:visible').val())));
  });
  $('.brain_storm_round_question:visible').on("input", function() {
    return $('.brainstorm-question-area:visible').text($('.brain_storm_round_question:visible').val());
  });
  $("#target_ideas_meter").on("change", function() {
    if ($("#target_ideas_meter").val() === "true") {
      return $("#brain_storm_round_target_ideas").show();
    } else {
      return $("#brain_storm_round_target_ideas").hide();
    }
  });
  $('.brain_storm_round_description:visible').on("input", function() {
    return $('.brainstorm-text-area:visible').text($('.brain_storm_round_description:visible').val());
  });
  $('.brain_storm_round_description:visible').trigger('input');
  $('.brain_storm_round_question:visible').trigger('input');
  $('.brain_storm_round_duration_hours:visible').trigger('change');
  if ($('.brain_storm_round_duration:visible').length > 0) {
    $('#brain_storm_round_duration').trigger('change');
  }
  $("#brain_storm_round_score_type").on("change", function() {
    var prev_val, selected_val;
    selected_val = $(this).val();
    prev_val = $("#prev_score_type").val();
    if ($("#mission_started").val() === "true") {
      if (selected_val !== prev_val) {
        return $("#alert_review").val(true);
      } else if (selected_val === prev_val) {
        return $("#alert_review").val(false);
      }
    }
  });
  $("#solo_checked").on("change", function() {
    var prev_val, selected_val;
    selected_val = $(this).val();
    prev_val = $("#prev_score_type").val();
    if ($("#mission_started").val() === "true") {
      if (selected_val !== prev_val) {
        return $("#alert_review").val(true);
      } else if (selected_val === prev_val) {
        return $("#alert_review").val(false);
      }
    }
  });
  $('.brain_storm_round_submit').click(function(e) {
    var brainstorm_data, brainstorm_form;
    if ($(".brain_storm_round_question:visible").val() === "" || $(".brainstorm_text").val() === "") {
      $('.toast').remove();
      Materialize.toast("Question or BrainStorm text can not be empty.", 2000);
      e.preventDefault();
    } else if ($("#brain_storm_round_is_critical").val() === "") {
      $('.toast').remove();
      Materialize.toast("Critical can not be empty.", 2000);
      e.preventDefault();
    } else if ($("#brain_storm_round_duration_hours").val() === "") {
      $('.toast').remove();
      Materialize.toast("Time can not be empty.", 2000);
      e.preventDefault();
    } else if ($("#alert_review").val() === "true") {
      $('.toast').remove();
      Materialize.toast("You can not change review type now", 2000);
      e.preventDefault();
    } else if ($('#brain_storm_round_is_reminder_required').val() === 'true' && Date.parse($('.brainstorm-start-date-area').text()) > Date.parse($('#brain_storm_round_notification_date').val().split('/')[2] + '-' + $('#brain_storm_round_notification_date').val().split('/')[1] + '-' + $('#brain_storm_round_notification_date').val().split('/')[0] + ' ' + $('#brain_storm_round_notification_time').val())) {
      $('.toast').remove();
      Materialize.toast("Brain storm round notification date should be be greater than round start date", 2000);
      e.preventDefault();
    } else {
      if (!$('.brain_round_form').hasClass('disabled')) {
        e.preventDefault();
        $('.brain_round_form').addClass('disabled');
        if ($("#target_ideas_meter").prop("checked") === false) {
          $("#brain_storm_round_target_ideas").remove();
        }
        brainstorm_form = document.getElementById("brain_round_form_submit");
        brainstorm_data = new FormData(brainstorm_form);
        $.ajax({
          type: $("#brain_round_form_submit").attr("method"),
          url: $("#brain_round_form_submit").attr("action"),
          data: brainstorm_data,
          processData: false,
          contentType: false,
          success: function(response) {
            var $errors_content;
            disable_default_form_submit();
            show_notice_toast();
            $(document).trigger('ajax:complete');
            if ($(response).filter("#error_explanation").length > 0) {
              $errors_content = $(response).filter("#error_explanation")[0];
              Materialize.toast($errors_content, 4000);
              return $(document).trigger('ajax:complete');
            } else {
              $('.modal.open').modal('close');
              $('#mission-builder-new').html(response);
              Materialize.toast("Success", 4000);
              return $(document).trigger('ajax:complete');
            }
          }
        });
      }
    }
  });
  $('.qa_round_form').removeClass('disabled');
  $('.qa_round_duration:visible').on('change', function() {
    var date, time;
    date = new Date(parseFloat($(this).data('start-date')));
    $('.brainstorm-start-date-area:visible').text(date_to_string(date));
    $('.brainstorm-end-date-area:visible').text(date_to_string(get_end_date($('.qa_round_duration:visible').data('start-date'), $('.qa_round_duration:visible').data('duration'), $('.qa_round_duration_hours:visible').val())));
    time = $('.qa_round_duration_hours:visible').val().split(":");
    return $('.qa-duration-area:visible').text($('.qa_round_duration:visible').data('duration') + "D " + time[0] + "H " + time[1] + "M");
  });
  $(document).on('change', '.qa_selected_duration_options', function(e) {
    var time;
    if ($(e.target).val() !== "") {
      $('.brainstorm-end-date-area:visible').text(date_to_string(get_end_date($('.qa_round_duration:visible').data('start-date'), parseInt($(e.target).val()), $('.qa_round_duration_hours:visible').val())));
      time = $('.qa_round_duration_hours:visible').val().split(":");
      $('.brainstorm-duration-area:visible').text($(e.target).val() + "D " + time[0] + "H " + time[1] + "M");
    }
  });
  $('.qa_round_duration_hours:visible').on("change", function() {
    var time;
    time = $('.qa_round_duration_hours:visible').val().split(":");
    $('.brainstorm-duration-area:visible').text($('#brain_storm_round_duration.qa_selected_duration_options').val() + "D " + time[0] + "H :" + time[1] + "M");
    return $('.brainstorm-end-date-area:visible').text(date_to_string(get_end_date($('.qa_round_duration:visible').data('start-date'), parseInt($('#brain_storm_round_duration.qa_selected_duration_options').val()), $('.qa_round_duration_hours:visible').val())));
  });
  $('.question_round_question:visible').on("input", function() {
    return $('.brainstorm-question-area:visible').text($(this).val());
  });
  $('.qa_round_description:visible').on("input", function() {
    return $('.qa-text-area:visible').text($(this).val());
  });
  $('.qa_round_description:visible').trigger('input');
  $('.question_round_question:visible').trigger('input');
  $('.qa_round_duration_hours:visible').trigger('change');
  $('.qa_round_duration:visible').trigger('change');
  $('#answer_count').change(function() {
    var results, selected_val, start;
    selected_val = $(this).val();
    start = 0;
    while (start < selected_val) {
      $(this).closest("form").find("input[name='brain_storm_round[answer_choices_attributes][" + start + "][answer]']").css("display", "block");
      $(this).closest("form").find("input[name='brain_storm_round[answer_choices_attributes][" + start + "][marked]']").css("display", "block");
      $(this).closest("form").find("input[name='brain_storm_round[answer_choices_attributes][" + start + "][choice_number]']").addClass('displayed').removeClass('invisibled');
      start++;
    }
    if (start !== 7) {
      results = [];
      while (start <= 7) {
        $(this).closest("form").find("input[name='brain_storm_round[answer_choices_attributes][" + start + "][answer]']").css("display", "none");
        $(this).closest("form").find("input[name='brain_storm_round[answer_choices_attributes][" + start + "][marked]']").css("display", "none");
        $(this).closest("form").find("input[name='brain_storm_round[answer_choices_attributes][" + start + "][choice_number]']").addClass('invisibled').removeClass('displayed');
        results.push(start++);
      }
      return results;
    }
  });
  $('#question_answer_submit:visible').click(function(e) {
    var qa_data, qa_form, reqlength, value;
    reqlength = $(".answer-choice-text:visible").length;
    console.log(reqlength);
    value = $(".answer-choice-text:visible").filter(function() {
      return this.value !== '';
    });
    if ($(".question_round_question:visible").val() === "" || $(".question_round_text:visible").val() === "") {
      $('.toast').remove();
      Materialize.toast("Question or BrainStorm text can not be empty.", 2000);
      return e.preventDefault();
    } else if ($("#qa_round_is_critical").val() === "") {
      $('.toast').remove();
      Materialize.toast("Critical can not be empty.", 2000);
      return e.preventDefault();
    } else if (value.length >= 0 && value.length !== reqlength) {
      $('.toast').remove();
      Materialize.toast("Answer Choice Text can not be empty.", 2000);
      return e.preventDefault();
    } else {
      if (!$('.qa_round_form').hasClass('disabled')) {
        e.preventDefault();
        $('.qa_round_form').addClass('disabled');
        $(".question-check:hidden").remove();
        $(".answer-choice-text:hidden").remove();
        $(".invisibled").remove();
        qa_form = document.getElementById("qa_round_form_submit");
        qa_data = new FormData(qa_form);
        return $.ajax({
          type: $("#qa_round_form_submit").attr("method"),
          url: $("#qa_round_form_submit").attr("action"),
          data: qa_data,
          processData: false,
          contentType: false,
          success: function(response) {
            var $errors_content;
            disable_default_form_submit();
            show_notice_toast();
            $(document).trigger('ajax:complete');
            if ($(response).filter("#error_explanation").length > 0) {
              $errors_content = $(response).filter("#error_explanation")[0];
              Materialize.toast($errors_content, 4000);
              return $(document).trigger('ajax:complete');
            } else {
              $('.modal.open').modal('close');
              $('#mission-builder-new').html(response);
              Materialize.toast("Success", 4000);
              return $(document).trigger('ajax:complete');
            }
          }
        });
      }
    }
  });
  re_register_round_click = function() {
    var element;
    $('.round-edit').on("click", function() {});
    element = $(this);
    return $.ajax({
      url: element.data("edit-url"),
      data: {},
      success: function(data) {
        $("#modal-round-edit").find('.round-edit-body').html(data);
        init_time_picker();
        $('select').material_select();
        $("#modal-round-edit").find($(".modal-close")).click(function() {
          return $("#modal-round-edit").modal('close');
        });
        setTimeout(function() {
          break_rounds_ready();
          brief_rounds_ready();
          return brainstorm_rounds_ready();
        }, 100);
        return init_date_picker();
      }
    });
  };
  $(".brainstorm-round-show-advance-fields").on("click", function() {
    $(".brainstorm-round-adv").hide();
    $(".brainstorm-round-advance-fields").removeClass('hide');
    return brainstorm_round_image();
  });
  $(".brainstorm-round-hide-advance-fields").on("click", function() {
    $(".brainstorm-round-advance-fields").addClass('hide');
    $(".brainstorm-round-adv").show();
    return brainstorm_round_image();
  });
  $('.image-upload').hover((function() {
    $('.mission-edit-mission-pic').css('opacity', '0.6');
    return $('.add-opacity').css('opacity', '1');
  }), function() {
    $('.mission-edit-mission-pic').css('opacity', '1');
    return $('.add-opacity').css('opacity', '0');
  });
  return brainstorm_round_image();
};

$(document).ready(brainstorm_rounds_ready);

$(document).on('ajax:complete', brainstorm_rounds_ready);
