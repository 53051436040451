/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
// You can use CoffeeScript in this file: http://coffeescript.org/
import {tinymce, tinymce_settings} from '../shared/tinymce';
window.break_round_image = function() {
  $(".break-round-image:visible").off("change");
  return $(".break-round-image:visible").on("change", function() {
    return Materialize.toast("Please save the round to preview the image.", 2000);
  });
};
window.break_rounds_ready = function() {
  tinymce.init(tinymce_settings);
  //Preview of break Round
  $('.break_round_duration:visible').on("change", function() {

    var date = new Date(parseFloat($(this).data('start-date')));

    // $('.break-start-date:visible')[0].setAttribute("class", "preview-h5")
    // $('.break-start-date:visible').text("Start date")
    $('.break-start-date-area').text(date_to_string(date));

    // $('.break-end-date:visible')[0].setAttribute("class", "preview-h5")
    // $('.break-end-date:visible').text("End date")
    $('.break-end-date-area').text(date_to_string(get_end_date($('.break_round_duration:visible').data('start-date'),$('.break_round_duration:visible').data('duration'),$('.break_round_duration_hours:visible').val())));

    // $('.break-duration:visible')[0].setAttribute("class", "preview-h5")
    // $('.break-duration:visible').text("Break Duration")
    var time = $('.break_round_duration_hours').val().split(":");
    return $('.break-duration-area').text($('.break_round_duration:visible').val()+"D "+ time[0] + "H "+time[1]+"M");
  });

  $('.break_round_duration_hours:visible').on("change", function() {
    // $('.break-duration:visible')[0].setAttribute("class", "preview-h5")
    // $('.break-duration:visible').text("Break Duration")
    var time = $('.break_round_duration_hours:visible').val().split(":");
    $('.break-duration-area').text($('.break_round_duration:visible').val()+"D "+ time[0] + "H :"+time[1]+"M");
    return $('.break-end-date-area').text(date_to_string(get_end_date($('.break_round_duration:visible').data('start-date'),$('.break_round_duration:visible').data('duration'),$('.break_round_duration_hours:visible').val())));
  });

  $('.break_round_description:visible').on("input", function() {
    return (
      // $('.break-text-preview:visible')[0].setAttribute("class", "preview-h5")
      // $('.break-text-preview:visible').text("Break Text")
      $('.break-text-preview-area').text($('.break_round_description:visible').val())
    );
  });

  $(".break-round-show-advance-fields").on("click", function() {
    $(".break-round-adv").hide();
    $(".break-round-advance-fields").removeClass('hide');
    return break_round_image();
  });

  $(".break-round-hide-advance-fields").on("click", function() {
    $(".break-round-advance-fields").addClass('hide');
    $(".break-round-adv").show();
    return break_round_image();
  });

  $('.break_round_duration:visible').trigger('change');
  $('.break_round_duration_hours:visible').trigger('change');
  $('.break_round_description:visible').trigger('input');
  return break_round_image();
};
$(document).ready(break_rounds_ready);
$(document).on('ajax:complete', break_rounds_ready);
