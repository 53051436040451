document.addEventListener('DOMContentLoaded', () => {  
  $('.submit-selection-review').on('click', function(){
    $('#movement_type').val(this.id)
    var valid = []
    $.each($('.rbi-inner input'), function(index, value){      
      if($('input[name=' + '"' + value.name + '"' + ']:checked').length === 0){
        valid.push(false)
      }
      else{
        valid.push(true)
      }
    });
    $.each($('.impact-radio input'), function(index, value){      
      if($('input[name=' + '"' + value.name + '"' + ']:checked').length === 0){
        valid.push(false)
      }
      else{
        valid.push(true)
      }
    });
    $.each($('.star-rates input'), function(index, value){      
      if($('input[name=' + '"' + value.name + '"' + ']:checked').length === 0){
        valid.push(false)
      }
      else{
        valid.push(true)
      }
    });

    var uniqueValues = [...new Set(valid)];
    if(uniqueValues.length === 1)
    {
      $('.selection_review_form').submit();
    }
    else
    {
      $('.toast').remove()
      Materialize.toast("All criteria must be filled in before saving", 2000)
    }
  });
  $('#button1').click(function(){
    $(".mycol").removeClass("l4").addClass("l1")
    $("#resizeColumn").removeClass("l1").addClass("l4")
    $(".review-Headings").removeClass("l4").addClass("l7")
    $(".hide-div").hide()
    $("#resizeColumn").show()

  });
  $("#button").click(function(){
    console.log("button click")
    if($(".mycol").hasClass("l1")){
      console.log("fold")
      $(".mycol").removeClass("l1").addClass("l4")
      $(".hide-div").show()
      $("#resizeColumn").hide()
      $(".review-Headings").removeClass("l7").addClass("l4")           
    }      
  })
});
