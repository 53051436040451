/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
var $ = jQuery;
import 'materialize-css/dist/js/materialize';
import 'webpack-jquery-ui/draggable';
import 'kbw-countdown/dist/js/jquery.plugin';
import 'kbw-countdown/dist/js/jquery.countdown';

$(function() {
	var validateEmail;
	$(".pass_field").keyup(
		function() {
			if(!!$(".pass_field").val() && ($(".pass_field").val().length < 6)) {
				$(".tick-pass-1").addClass("hide");
				return $(".clear-pass-1").removeClass("hide");
			} else {
				$(".tick-pass-1").removeClass("hide");
				return $(".clear-pass-1").addClass("hide");
			}
	});

	$(".pass_confirmation").keyup(
		function() {
			if($(".pass_field").val() === $(".pass_confirmation").val()) {
				$(".tick-pass-2").removeClass("hide");
				return $(".clear-pass-2").addClass("hide");
			} else {
				$(".tick-pass-2").addClass("hide");
				return $(".clear-pass-2").removeClass("hide");
			}
	});

	$(".signin-pass").keyup(
		function() {
			if($(".signin-pass").val().length < 6) {
				$(".tick-pass-3").addClass("hide");
				return $(".clear-pass-3").removeClass("hide");
			} else {
				$(".tick-pass-3").removeClass("hide");
				return $(".clear-pass-3").addClass("hide");
			}
	});

	window.on_input = function(input){
		var start = input.selectionStart;
		var end = input.selectionEnd;

		input.value = input.value.toLowerCase();
		input.setSelectionRange(start, end);
	};

	$("#Email").on('input', function() {
		return on_input($(this)[0]);
		});

	$("#add-user").on("click", function() {
		var name = $("#Name").val();
		var email= $("#Email").val();
		email= email.toLowerCase();
		if (name && email) {
			var length;
			console.log(name+" "+email);
			$("#Name").val("");
			$("#Email").val("");

			var id = (length = $("#users-table tr").length);
			var row = $("#users-table").find('tbody')[0].insertRow(0);
			var cell1 = row.insertCell(0);
			var cell2 = row.insertCell(1);
			var cell3 = row.insertCell(2);
			cell1.innerHTML = "<div><input type='text' class='center-align' id='user-name-".concat(id, "' name='users[").concat(id, "][name]' value='").concat(name, "'/></div>");
			cell2.innerHTML = "<div><input type='text' class='center-align' id='user-email-".concat(id, "' name='users[").concat(id, "][email]' value='").concat(email, "' oninput='on_input(this)'/></div>");

			// elems = []
			// elems.push "<select id='user-role-#{id}' name='users[#{id}][role]'><option value='Mission manager'>Mission Manager</option><option value='System admin'>System Admin</option><option value='Company captain'>Company Captain</option><option value='Agent'>Agent</option></select>"
			// elems.push "<input type='text' id='user-name-#{id}' name='users[#{id}][name]' value='#{name}'/>"
			// elems.push "<input type='text' id='user-email-#{id}' name='users[#{id}][email]' value='#{email}'/>"


			cell3.innerHTML = "<select id='user-role-".concat(id, "' name='users[").concat(
                id,
                "][role]'><option value='Agent'>Agent</option><option value='Mission manager'>Mission Manager</option></select>"
            );
			$('select').material_select();
			$('#modal-add-user').modal('close');
		} else {
			$('.toast').remove();
			Materialize.toast("Please enter Name and Email.", 2000);
		}

  return $('.save-csv-list').off;
	});
	$('.save-csv-list').on('click', function() {
        return $('#add-invite-form').submit();
    });

	$("#add-users-csv").change(function() {	  
		var file = $('#add-users-csv')[0].files[0];
		if (!!file) {
			var reader = new FileReader();
			reader.readAsText(file);
			return reader.onload = function(ee) {
				var imported_data = Papa.parse(ee.target.result, {}).data;
				var data = 1;
				var id = 0;
				return (function() {
					var result = [];
					while(data) {
						data = imported_data.shift();
					
						if (validateEmail(data[0]) || validateEmail(data[1])) {						
							var name = data[0];
							var email = data[1].toLowerCase();
							id = id + 1;
						
							var elems = $('#add-invite-form');
							elems.append("<input type='hidden' id='user-firstname-".concat(id, "' name='users[").concat(id, "][first_name]' value='").concat(first_name, "'/>"));
							elems.append("<input type='hidden' id='user-lastname-".concat(id, "' name='users[").concat(id, "][last_name]' value='").concat(last_name, "'/>"));
							result.push(elems.append("<input type='hidden' id='user-email-".concat(id, "' name='users[").concat(id, "][email]' value='").concat(email, "'/>")));
						} else {
							result.push(undefined);
						}
					}
					return result;
				})();
			};
		}
	});

	return validateEmail = function validateEmail(email) {
		var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(email);
	};
});
