var $, application_ready, countdown;

$ = jQuery;

import 'materialize-css/dist/js/materialize';

import 'webpack-jquery-ui/draggable';

import 'kbw-countdown/dist/js/jquery.plugin';

import 'kbw-countdown/dist/js/jquery.countdown';

countdown = $.fn.countdown = jQuery.fn.countdown;

application_ready = function() {
  var ajax_ready, element, on_input, set_date_end, set_date_start, toggle_user_invite_select, validateEmail;
  if ($('.red-button-review-area:visible').length > 0) {
    $(".review-now").last().get(0).scrollIntoView();
  } else if ($('#current-active-round').length > 0) {
    window.location.hash = '';
    window.location.hash = '#current-active-round';
  }
  if ($('#open-end-note-btn').length > 0) {
    Appcues.track("Mission ended");
  }
  if ($('.open-mission-leader-board').length > 0) {
    if (window.location.href.indexOf("leaderboard") > -1) {
      element = $('.open-mission-leader-board').last();
      $.ajax({
        url: element.data("url"),
        data: {},
        success: function(data) {
          $("#mission-leaderboard").modal('open');
          $("#mission-leaderboard.modal").html(data);
          $("#mission-leaderboard.modal").find($('ul.tabs')).tabs();
          return $("#mission-leaderboard.modal").find($(".modal-close")).click(function() {
            return $("#mission-leaderboard").modal('close');
          });
        }
      });
    }
  }
  $("a#refresh.action-timeline-refresh").on('click', function() {
    window.location.reload(false);
  });
  $('#copy-invite-url').on('click', function() {
    var range;
    if (document.selection) {
      range = document.body.createTextRange();
      range.moveToElementText($('#invite-url-area').get(0));
    } else if (window.getSelection) {
      range = document.createRange();
      range.selectNode($('#invite-url-area').get(0));
      window.getSelection().addRange(range);
    }
    return document.execCommand('copy');
  });
  $('#open-end-note-btn').off("click");
  $('#open-end-note-btn').on("click", function(e) {
    e.preventDefault();
    element = $(this);
    if (element.data("can-review")) {
      return $.ajax({
        url: element.data("url"),
        data: {},
        success: function(data) {
          if (data["has_reviewed"] !== void 0 && data["has_reviewed"] === true) {
            return $('#open-end-note.modal').modal('open');
          } else if (data["has_reviewed"] !== void 0 && data["has_reviewed"] === false) {
            $('.toast').remove();
            return Materialize.toast("Please complete the review of previous round to proceed", 5000);
          } else {
            return $('#open-end-note.modal').modal('open');
          }
        },
        error: function(data) {}
      });
    } else {
      return $('#open-end-note.modal').modal('open');
    }
  });
  ajax_ready = function() {
    var date, i, n, str;
    $('.disabled-mission').click(function() {
      var max_missions;
      max_missions = $(this).data('max-missions');
      $('.toast').remove();
      return Materialize.toast("You have only " + max_missions + " mission(s) in your package.", 10000);
    });
    $('.disabled-agents').click(function() {
      var max_agents;
      max_agents = $(this).data('max-agents');
      $('.toast').remove();
      return Materialize.toast("Your current plan only accepts max " + max_agents + " invited agents", 10000);
    });
    $('#mission_ending_note').on("input", function() {
      return $('#end-note-area').text($('#mission_ending_note').val());
    });
    $('#mission_ending_note').trigger('input');
    $('.draggable').draggable({
      cancel: "menue",
      revert: "invalid",
      containment: "document",
      helper: "clone",
      cursor: "move",
      start: function(event, ui) {
        return $(ui.helper).width(ui.helper.prevObject.width());
      }
    });
    $('.template').on("click", function() {
      $('.mb-select').addClass('template-unselected');
      return $(this).find('.mb-select').removeClass('template-unselected');
    });
    $('#save-as-template').on("click", function() {
      element = $(this);
      return $.ajax({
        type: 'POST',
        url: element.data("url"),
        data: {},
        success: function(data) {
          if (data["is_template"]) {
            return $('#save-as-template').find('.drag-box-template').text("Saved as template");
          } else {
            return $('#save-as-template').find('.drag-box-template').text("Save as template");
          }
        },
        error: function(data) {}
      });
    });
    $('#create-mission').on("click", function() {
      var template_url;
      if ($('#from-scratch:visible').length > 0) {
        if (!$(".mission_title").val()) {
          $('.toast').remove();
          Materialize.toast("Mission Title can not be empty.", 2000);
          $(".mission_title").focus();
          return;
        }
        if (!$(".desc_area").val()) {
          $('.toast').remove();
          Materialize.toast("Description can not be empty.", 2000);
          $(".desc_area").focus();
          return;
        }
        if (!$(".from_name").val()) {
          $('.toast').remove();
          Materialize.toast("From Name can not be empty.", 2000);
          $(".from_name").focus();
          return;
        }
        return $('.new_mission_form').submit();
      } else {
        template_url = $('.mb-select').not('.template-unselected').data('url');
        if (!template_url) {
          $('.toast').remove();
          return Materialize.toast("No template selected", 2000);
        } else {
          console.log(template_url);
          return $.ajax({
            type: 'POST',
            url: template_url,
            data: {
              company_id: $('#company').val()
            },
            success: function(data) {},
            error: function(data) {}
          });
        }
      }
    });
    $('#open-preview').on("click", function() {
      element = $(this);
      return $.ajax({
        url: element.data("preview-url"),
        data: {},
        success: function() {}
      });
    });
    $('.mission-management-tabs .switch input').off('change');
    $('.mission-management-tabs .switch input').on('change', function(event) {
      if (this.checked) {
        if ($('a.publishing_link').text() !== 'Published') {
          return $('a.publishing_link').click();
        }
      } else {
        if ($('a.publishing_link').text() === 'Published') {
          return $('a.publishing_link').click();
        }
      }
    });
    $('.mission_options').hide();
    $('.time_options').hide();
    if (window.location.search) {
      str = window.location.search;
      str = str.split('=');
      if (str[0] === '?manager') {
        $('.mission_options').show();
        $('#manager_options').val(str[1]);
        $("#filter_options").val('By Mission Manager');
      } else if (str[0] === '?start_date') {
        n = str[1].search('&end_date');
        if (n === 10) {
          date = str[1].split('&');
          $('.time_options').show();
          set_date_start(date[0]);
          set_date_end(str[2]);
        } else {
          $('.time_options').show();
          set_date_start(str[1]);
        }
      } else if (str[0] === '?end_date') {
        $('.time_options').show();
        set_date_end(str[1]);
      }
    }
    $(".apply").click(function() {
      var end_date, start_date, url;
      if ($("#start_date").val()) {
        start_date = $("#start_date").val();
      }
      if ($("#end_date").val()) {
        end_date = $("#end_date").val();
      }
      if (start_date && end_date) {
        url = '/commander_dashboard?start_date=' + start_date + '&end_date=' + end_date;
      } else if (start_date) {
        url = '/commander_dashboard?start_date=' + start_date;
      } else if (end_date) {
        url = '/commander_dashboard?end_date=' + end_date;
      }
      return window.location.replace(url);
    });
    $("#filter_options").change(function() {
      if (!this.selectedOptions[0].value) {
        $('.mission_options').hide();
        $('.time_options').hide();
      }
      if (this.selectedOptions[0].value === "By Mission Manager") {
        $('.mission_options').show();
        return $('.time_options').hide();
      } else if (this.selectedOptions[0].value === "By Date") {
        $('.mission_options').hide();
        return $('.time_options').show();
      }
    });
    $("#manager_options").change(function() {
      var manager, url;
      if (this.selectedOptions[0].value) {
        manager = this.selectedOptions[0].value;
        url = '/commander_dashboard?manager=' + manager;
        return window.location.replace(url);
      }
    });
    $('.time-caps').each(function() {
      var diff, newtime;
      newtime = new Date(parseFloat($(this).data('time')));
      diff = (newtime - new Date()) / 1000;
      return $(this).countdown({
        until: diff,
        compact: true,
        layout: '<b>{dn}D : {hnn}H{sep}{mnn}M{sep}{snn}S</b> {desc}',
        description: ''
      });
    });
    $('.time').each(function() {
      var diff, newtime;
      newtime = new Date(parseFloat($(this).data('time')));
      diff = (newtime - new Date()) / 1000;
      return $(this).countdown({
        until: diff,
        compact: true,
        layout: '<b>{dn}d {hnn}h{sep}{mnn}m{sep}{snn}s</b> {desc}',
        description: ''
      });
    });
    console.log("Running timers");
    i = 0;
    $('div[id^=\'timer-active-\']').each(function() {
      var diff, id, newtime;
      id = this.id;
      newtime = new Date($('#time-val-active-' + i)[0].value);
      diff = new Date(newtime.getFullYear(), newtime.getMonth(), newtime.getDate(), newtime.getHours(), newtime.getMinutes());
      $('#' + id).countdown({
        until: diff,
        compact: true,
        layout: '<b>{dn}d {hnn}h {mnn}m {snn}s</b> {desc}',
        description: ''
      });
      return i++;
    });
    i = 0;
    $('div[id^=\'timer-future-\']').each(function() {
      var diff, id, newtime;
      id = this.id;
      newtime = new Date($('#time-val-future-' + i)[0].value);
      diff = new Date(newtime.getFullYear(), newtime.getMonth(), newtime.getDate(), newtime.getHours(), newtime.getMinutes());
      $('#' + id).countdown({
        until: diff,
        compact: true,
        layout: '<b>{dn}d {hnn}h {mnn}m {snn}s</b> {desc}',
        description: ''
      });
      return i++;
    });
    i = 0;
    $('div[id^=\'current-timer-\']').each(function() {
      var diff, id, newtime;
      id = this.id;
      newtime = new Date(parseFloat($('#current-round-timer-' + i)[0].value));
      diff = (newtime - new Date()) / 1000;
      if (diff >= 259200) {
        $(this).countdown({
          until: diff,
          compact: true,
          layout: '<b>{dn}d {hnn}h {mnn}m</b> {desc}',
          format: 'dHM',
          description: '',
          onExpiry: function(event) {
            var avatar_name;
            if ($("#add-idea:visible").length > 0) {
              $("#timeline-refresh").modal('open', {
                dismissible: true
              });
              $("a#cancel.action-timeline-refresh").show();
              $('.refresh-message').text("Continue to the next round or close this message if you want to finish your current action first.");
            } else {
              $("#timeline-refresh").modal('open', {
                dismissible: false
              });
              $("a#cancel.action-timeline-refresh").hide();
              avatar_name = $('.refresh-message').data('profile-name');
              $('.refresh-message').text("Well done " + avatar_name + ". Time's up for this mission round. Press continue to start your next objective for this mission. Good luck agent!");
            }
          }
        });
      } else {
        $(this).countdown({
          until: diff,
          compact: true,
          layout: '<b>{hnn}h {mnn}m {snn}s</b> {desc}',
          format: 'HMS',
          description: '',
          onExpiry: function(event) {
            var avatar_name;
            if ($("#add-idea:visible").length > 0) {
              $("#timeline-refresh").modal('open', {
                dismissible: true
              });
              $("a#cancel.action-timeline-refresh").show();
              $('.refresh-message').text("Continue to the next round or close this message if you want to finish your current action first.");
            } else {
              $("#timeline-refresh").modal('open', {
                dismissible: false
              });
              $("a#cancel.action-timeline-refresh").hide();
              avatar_name = $('.refresh-message').data('profile-name');
              $('.refresh-message').text("Well done " + avatar_name + ". Time's up for this mission round. Press continue to start your next objective for this mission. Good luck agent!");
            }
          }
        });
      }
      return i++;
    });
    return i = 0;
  };
  toggle_user_invite_select = function() {
    var dummy, id, ref;
    element = $(this);
    ref = element.attr('id').split('-'), dummy = ref[0], id = ref[1];
    if (element.text() === "add_circle_outline") {
      element.text("check_circle");
      element.parent().append("<input type='hidden' id='user-name-" + id + "' name='users[" + id + "][name]' value='" + (element.data('name')) + "'/>");
      element.parent().append("<input type='hidden' id='user-email-" + id + "' name='users[" + id + "][email]' value='" + (element.data('email')) + "'/>");
    } else {
      element.text("add_circle_outline");
      $("#user-name-" + id).remove();
      $("#user-email-" + id).remove();
    }
  };
  on_input = function(input) {
    var end, start;
    start = input.selectionStart;
    end = input.selectionEnd;
    input.value = input.value.toLowerCase();
    input.setSelectionRange(start, end);
  };
  $("#Email").on('input', function() {
    return on_input($(this)[0]);
  });
  $.expr[":"].contains = $.expr.createPseudo(function(arg) {
    return function(elem) {
      return $(elem).text().toUpperCase().indexOf(arg.toUpperCase()) >= 0;
    };
  });
  $("#am-search-user").keyup(function() {
    var filter;
    filter = $(this).val();
    $('table#invited-table tr').not(":contains(" + filter + ")").fadeOut();
    $("table#invited-table tr:contains(" + filter + ")").show();
  });
  $('#close-btn').on("click", function() {
    return $('#open-board').css("display", "none");
  });
  $("#add-invite").on("click", function() {
    var cell1, cell2, cell3, elems, email, id, length, name, row;
    name = $("#Name").val();
    email = $("#Email").val().toLowerCase();
    email = email.toLowerCase();
    if (name && email) {
      console.log(name + " " + email);
      $("#Name").val("");
      $("#Email").val("");
      id = length = $("#invited-table tr").length;
      row = $("#invited-table").find('tbody')[0].insertRow();
      cell1 = row.insertCell(0);
      cell2 = row.insertCell(1);
      cell3 = row.insertCell(2);
      cell1.innerHTML = name;
      cell2.innerHTML = email;
      elems = ["<i class='material-icons user-invite-add' id='usernew-" + id + "' data-name='" + name + "' data-email='" + email + "'>check_circle</i>"];
      elems.push("<input type='hidden' id='user-name-" + id + "' name='users[" + id + "][name]' value='" + name + "'/>");
      elems.push("<input type='hidden' id='user-email-" + id + "' name='users[" + id + "][email]' value='" + email + "'/>");
      cell3.innerHTML = elems.join('');
      return $("#usernew-" + length).click(toggle_user_invite_select);
    } else {
      $('.toast').remove();
      return Materialize.toast("Please enter Name and Email.", 2000);
    }
  });
  $('.user-invite-add').click(toggle_user_invite_select);
  $("#add-csv").change(function() {
    var file, reader;
    file = $('#add-csv')[0].files[0];
    reader = new FileReader();
    reader.readAsText(file);
    return reader.onload = function(ee) {
      var cell1, cell2, cell3, data, elems, email, id, imported_data, length, name, results, row;
      imported_data = Papa.parse(ee.target.result, {}).data;
      data = 1;
      results = [];
      while (data) {
        data = imported_data.shift();
        if (validateEmail(data[0]) || validateEmail(data[1])) {
          id = length = $("#invited-table tr").length;
          row = $("#invited-table").find('tbody')[0].insertRow();
          cell1 = row.insertCell(0);
          cell2 = row.insertCell(1);
          cell3 = row.insertCell(2);
          name = data[0];
          email = data[1].toLowerCase();
          cell1.innerHTML = name;
          cell2.innerHTML = email;
          elems = ["<i class='material-icons user-invite-add' id='usernew-" + id + "' data-name='" + name + "' data-email='" + email + "'>check_circle</i>"];
          elems.push("<input type='hidden' id='user-name-" + id + "' name='users[" + id + "][name]' value='" + name + "'/>");
          elems.push("<input type='hidden' id='user-email-" + id + "' name='users[" + id + "][email]' value='" + email + "'/>");
          cell3.innerHTML = elems.join('');
          results.push($("#usernew-" + length).click(toggle_user_invite_select));
        } else {
          results.push(void 0);
        }
      }
      return results;
    };
  });
  validateEmail = function(email) {
    var re;
    re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };
  set_date_start = function(date) {
    return $("#start_date").pickadate({
      format: 'dd/mm/yyyy',
      selectMonths: true,
      selectYears: 15,
      firstDay: 1
    }).val(date);
  };
  set_date_end = function(date) {
    return $("#end_date").pickadate({
      format: 'dd/mm/yyyy',
      selectMonths: true,
      selectYears: 15,
      firstDay: 1
    }).val(date);
  };
  ajax_ready();
  return $(document).on('ajax:complete', ajax_ready);
};

$(document).ready(application_ready);
