/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
// You can use CoffeeScript in this file: http://coffeescript.org/
$(function() {
    $('#package-dropdown').on('click', '.init', function() {
        return $(this).closest('#package-dropdown').children('li:not(.init)').toggle();
    });    
    
    var allOptions = $('#package-dropdown').children('li:not(.init)');
    $('#package-dropdown').on('click', 'li:not(.init)', function() {
        allOptions.removeClass('selected');
        $(this).addClass('selected');
        $('#selected-package').val($(this).data('value'));
        $('#package-dropdown').children('.init').html($(this).html());
        return allOptions.toggle();
    });
});




// $("#shahjji").on("click", ".init", function() {
//     $(this).closest("#shahjji").children('li:not(.init)').toggle();
// });
// var allOptions = $("#shahjji").children('li:not(.init)');
// $("#shahjji").on("click", "li:not(.init)", function() {
//     allOptions.removeClass('selected');
//     $(this).addClass('selected');
//     $("#shahjji").children('.init').html($(this).html());
//     allOptions.toggle();
// });
