var get_score_data, is_score_given;

import {
  start_timers
} from './common';

is_score_given = false;

$(document).on('click', '.open-review-board', function(e) {
  var element, index;
  e.preventDefault();
  $('.time-disposeable').countdown('destroy');
  index = 0;
  element = $(this);
  return $.ajax({
    url: element.data("round-idea-review"),
    data: {
      index: index
    },
    success: function(data) {
      $('#review-board').modal('open');
      return start_timers();
    }
  });
});

$(document).on('click', '.star', function(e) {
  is_score_given = true;
  $('.selected-btn').removeClass('selected-btn');
  return $(this).addClass('selected-btn');
});

$(document).on("click", '.idea-questions.simple>.btn', function() {
  var active_element, element;
  element = $(this);
  active_element = $('.idea-questions.simple .selected-btn');
  if (active_element) {
    active_element.removeClass('selected-btn');
  }
  if (active_element) {
    active_element.css("background", "white");
  }
  element.addClass('selected-btn');
  element.css("background", "#8dc63f");
  return is_score_given = true;
});

$('a#mobile-next-idea').on("click", function(e) {
  var _href, data, score_data;
  if (is_score_given) {
    score_data = get_score_data($('.selected-btn'));
    data = {
      idea_score_params: score_data,
      index: 1
    };
    _href = $(this).attr("href");
    return $(this).attr("href", _href + ("?" + ($.param(data))));
  } else {
    e.preventDefault();
    $('.toast').remove();
    return Materialize.toast("Please reviewe this idea before going to next", 5000);
  }
});

$(document).on("click", '.idea-questions >.red-hover', function() {
  var active_element, element;
  element = $(this);
  active_element = $('.idea-questions .selected-btn');
  if (active_element) {
    active_element.removeClass('selected-btn');
  }
  if (active_element) {
    active_element.css("background", "white");
  }
  element.addClass('selected-btn');
  element.css("background", "#ff0000");
  return is_score_given = $('.selected-btn').length === 2;
});

$(document).on("click", '.idea-questions >.yellow-hover', function() {
  var active_element, element;
  element = $(this);
  active_element = $('.idea-questions .selected-btn');
  if (active_element) {
    active_element.removeClass('selected-btn');
  }
  if (active_element) {
    active_element.css("background", "white");
  }
  element.addClass('selected-btn');
  element.css("background", "rgb(255, 224, 0)");
  return is_score_given = $('.selected-btn').length === 2;
});

$(document).on("click", '.idea-questions >.green-hover', function() {
  var active_element, element;
  element = $(this);
  active_element = $('.idea-questions .selected-btn');
  if (active_element) {
    active_element.removeClass('selected-btn');
  }
  if (active_element) {
    active_element.css("background", "white");
  }
  element.addClass('selected-btn');
  element.css("background", "rgb(141, 198, 63)");
  return is_score_given = $('.selected-btn').length === 2;
});

$(document).on("click", '.investment-questions>.red-hover', function() {
  var active_element, element;
  element = $(this);
  active_element = $('.investment-questions .selected-btn');
  if (active_element) {
    active_element.removeClass('selected-btn');
  }
  if (active_element) {
    active_element.css("background", "white");
  }
  element.addClass('selected-btn');
  element.css("background", "#ff0000");
  return is_score_given = $('.selected-btn').length === 2;
});

$(document).on("click", '.investment-questions>.yellow-hover', function() {
  var active_element, element;
  element = $(this);
  active_element = $('.investment-questions .selected-btn');
  if (active_element) {
    active_element.removeClass('selected-btn');
  }
  if (active_element) {
    active_element.css("background", "white");
  }
  element.addClass('selected-btn');
  element.css("background", "rgb(255, 224, 0)");
  return is_score_given = $('.selected-btn').length === 2;
});

$(document).on("click", '.investment-questions>.green-hover', function() {
  var active_element, element;
  element = $(this);
  active_element = $('.investment-questions .selected-btn');
  if (active_element) {
    active_element.removeClass('selected-btn');
  }
  if (active_element) {
    active_element.css("background", "white");
  }
  element.addClass('selected-btn');
  element.css("background", "rgb(141, 198, 63)");
  return is_score_given = $('.selected-btn').length === 2;
});

$(document).on('click', '#next-idea-btn', function(e) {
  var element, score_data;
  e.preventDefault();
  if (is_score_given) {
    if ($(this).hasClass('clicked')) {
      return;
    }
    $(this).addClass('clicked');
    score_data = get_score_data($('.selected-btn'));
    $('.time-disposeable').countdown('destroy');
    element = $(this);
    return $.ajax({
      url: element.data("round-idea-review"),
      data: {
        idea_score_params: score_data,
        index: element.data('index') + 1
      },
      success: function(data) {
        $(this).removeClass('clicked');
        is_score_given = false;
        start_timers();
        return Materialize.toast("Score is recorded successfully", 3000);
      }
    });
  } else {
    $('.toast').remove();
    return Materialize.toast("Please review this idea before going to next", 5000);
  }
});

get_score_data = function(element) {
  var call_url, columns, id, score_data, selected_btns, type;
  id = element.data("idea-id");
  call_url = element.data("url");
  type = element.data('score-type');
  columns = [];
  selected_btns = $('.selected-btn');
  if (selected_btns.length > 0) {
    selected_btns.each(function() {
      var column_data;
      column_data = {
        column_name: $(this).data('column'),
        column_value: $(this).data('value')
      };
      return columns.push(column_data);
    });
  }
  score_data = {
    idea_id: id,
    score_type: type,
    columns_data: columns,
    review_comment: $('#review_comment').val()
  };
  return score_data;
};
