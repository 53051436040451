var get_score_data, is_score_given, start_timers;

import 'kbw-countdown/dist/js/jquery.plugin';

import 'kbw-countdown/dist/js/jquery.countdown';

is_score_given = false;

start_timers = function() {
  return $('.time-disposeable').each(function() {
    var diff, newtime;
    newtime = new Date(parseFloat($(this).data('time')));
    diff = (newtime - new Date()) / 1000;
    if (diff >= 259200) {
      return $(this).countdown({
        until: diff,
        compact: true,
        layout: '<b>{dn}d {hnn}h {mnn}m</b> {desc}',
        format: 'dHM',
        description: ''
      });
    } else {
      return $(this).countdown({
        until: diff,
        compact: true,
        layout: '<b>{hnn}h {mnn}m {snn}s</b> {desc}',
        format: 'HMS',
        description: ''
      });
    }
  });
};

$('.idea-questions >.btn.selected-btn').css("background", "#8dc63f");

$('.investment-questions >.btn.selected-btn.impact').css("background", "#8dc63f");

$('.investment-questions >.btn.selected-btn.investment').css("background", "#f75c1d");

$('.scored-star').addClass('fill-star');

$('#previous-idea-btn').on("click", function() {
  var element;
  $('.time-disposeable').countdown('destroy');
  element = $(this);
  return $.ajax({
    url: element.data("round-idea-review"),
    data: {
      index: element.data('index') - 1
    },
    success: function(data) {
      start_timers();
      return $(document).trigger('ajax:complete');
    }
  });
});

get_score_data = function(element) {
  var call_url, columns, id, score_data, selected_btns, type;
  id = element.data("idea-id");
  call_url = element.data("url");
  type = element.data('score-type');
  columns = [];
  selected_btns = $('.selected-btn');
  if (selected_btns.length > 0) {
    selected_btns.each(function() {
      var column_data;
      column_data = {
        column_name: $(this).data('column'),
        column_value: $(this).data('value')
      };
      return columns.push(column_data);
    });
  }
  score_data = {
    idea_id: id,
    score_type: type,
    columns_data: columns,
    review_comment: $('#review_comment').val()
  };
  return score_data;
};
