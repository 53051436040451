import { start_timers } from "./common";

import { disable_default_form_submit } from "./common";

$(function () {
  var autosize, textarea;
  $(document).find("select").material_select();
  $(".delete-icon").off("click");
  $(document).on("click", ".delete-icon", function () {
    var comment_id, idea_id, mission_id;
    mission_id = $("#mission_id").val();
    idea_id = $("#idea_id").val();
    comment_id = this.id;
    return $.ajax({
      url: "/ideas/" + idea_id + "/delete_comment",
      method: "POST",
      data: {
        comment_id: comment_id,
      },
      success: function (data) {
      },
    });
  });
  $(document).on("click", ".close-icon", function () {
    return $(".card-border-reply").hide();
  });
  $(document).on("click", ".bookmarked", function (e) {
    e.preventDefault();
    return $.ajax({
      type: "POST",
      url: $(this).data("idea-url"),
      dataType: "script",
    });
  });
  $(document).on("click", "#close-btn", function () {
    return $("#open-board").css("display", "none");
  });
  $(".open-idea-board").on("click", function () {
    var element;
    $(".time-disposeable").countdown("destroy");
    element = $(this);
    return $.ajax({
      url: element.data("edit-url"),
      data: {},
      success: function (data) {
        $("#brain-modal").find(".idea-board-content").html(data);
        start_timers();
        disable_default_form_submit();
        return setTimeout(function () {
          $(".modal").modal();
          $("#open-board.modal").modal("open");
          return $("select").material_select();
        }, 50);
      },
    });
  });
  $(document).on("click", ".select-wrapper", function (event) {
    return event.stopPropagation();
  });
  $(document).on("click", ".open-idea-dialogue", function (e) {
    var element;
    e.preventDefault();
    element = $(this);
    $(".idea_id").val(element.data("id"));
    return $.ajax({
      url: element.data("view-url"),
      data: {},
      success: function (data) {
        $("#add-idea-choice").remove();
        $("#idea-view-modal").find(".idea-view-content").html(data);
        $(".modal").modal();
        return setTimeout(function () {
          if ($("#card-description").length > 0) {
            $("#card-description.modal").modal("open");
          } else if ($("#add-idea-choice").length > 0) {
            $("#add-idea-choice.modal").moda("open");
          }
          return $("select").material_select();
        }, 50);
      },
    });
  });
  if (window.cordova) {
    textarea = document.querySelector("#chatbox-teaxtarea");
    autosize = function () {
      var el;
      el = this;
      setTimeout(function () {
        el.style.cssText = "height:auto; padding:0";
        el.style.cssText = "height:" + el.scrollHeight + "px";
      }, 0);
    };
    if (textarea) {
      textarea.style.cssText = "height:auto; padding:0";
      textarea.style.cssText = "height:" + textarea.scrollHeight + "px";
      textarea.addEventListener("keydown", autosize);
    }
  }
  $("#submit-idea").on("click", function (e) {
    e.preventDefault();
    if ($(".idea_title").val() === "") {
      $(".toast").remove();
      return Materialize.toast("All the fields need to be filled.", 2000);
    } else {
      Appcues.track("idea added");
      return $("form.idea_submission_form").submit();
    }
  });

  $(document).on("click", ".new-submit-idea", function(e) {
    e.preventDefault();
    
    var form = $(this).parents("form");
    
    if ($(".idea_title").val() === "") {
      $(".toast").remove();
      return Materialize.toast("All the fields need to be filled.", 2000);
    } else if ($("#idea_ideation_idea_category_id").length > 0 && $("#idea_ideation_idea_category_id").val() === "") {
      $(".toast").remove();
      return Materialize.toast("Please make sure to select a category.", 2000);
    } else {
      $(".submit-idea").attr("disabled", true);
      $(".submit-idea").attr("background", "#dddddd");
      // Appcues.track("idea added");
      
      var formData = new FormData(form[0]);
      
      $.ajax({
        method: "POST",
        url: form.attr("action"),
        data: formData,
        dataType: "script",
        contentType: false,
        processData: false,
        success: function(data) {
          $("#add-idea-close.modal-close").click();
          $("#filetype").val("");
          $("#filename").val("");
          $("#image").val("");
          $('#close-btn').click();
          $(".open-idea-board").click();
        },
      });
    }
  });

  $(document).on("click", ".submit-idea", function (e) {
    var datastring, form;
    e.preventDefault();
    if ($(".idea_title").val() === "") {
      $(".toast").remove();
      return Materialize.toast("All the fields need to be filled.", 2000);
    } else if (
      $("#idea_ideation_idea_category_id").length > 0 &&
      $("#idea_ideation_idea_category_id").val() === ""
    ) {
      $(".toast").remove();
      return Materialize.toast("Please make sure to select a category.", 2000);
    } else {
      $(".submit-idea").attr("disabled", true);
      $(".submit-idea").attr("background", "#dddddd");
      // Appcues.track("idea added");
      form = $(this).parents("form");
      datastring = form.serialize();
      return $.ajax({
        method: "POST",
        url: form.attr("action"),
        data: datastring,
        dataType: "script",
        success: function (data) {
          $("#add-idea-close.modal-close").click();
          $("#filetype").val("");
          $("#filename").val("");
          return $("#image").val("");
        },
      });
    }
  });
  return $(document).on("change", "#idea_image", function (event) {
    var file, fileObject, input, reader;
    file = void 0;
    input = void 0;
    reader = void 0;
    fileObject = {};
    input = $(event.currentTarget);
    file = input[0].files[0];
    if (!file) {
      return;
    }
    fileObject.filetype = file.type;
    fileObject.filename = file.name;
    reader = new FileReader();
    reader.onload = function (e) {
      var image_base64;
      image_base64 = e.target.result;
      fileObject.filecontent = image_base64;
      $("#filetype").val(fileObject.filetype);
      $("#filename").val(fileObject.filename);
      $("#image").val(
        fileObject.filecontent.replace(
          /^data:image\/(png|jpg|jpeg|gif);base64,/,
          ""
        )
      );
    };
    reader.readAsDataURL(file);
    return console.log(fileObject);
  });
});
