import Tribute from 'tributejs'
document.addEventListener('DOMContentLoaded', () => {
  var tribute = new Tribute({
    values: function (search, cb) {
        getUsernames(search, users => cb(users))
    },
    selectClass: 'element-hover',
    itemClass: 'main_tribute_li',
    menuItemTemplate: function (item) {
      return '<img style="width:25px;height:25px;margin-left:10px;margin-right: 10px; border-radius: 50%;" src="'+item.original.img_url + '">' +' <span>' + ' @'+ item.original.key + '</span>';
    },

  });
  
  if ($(".mention").length > 0){
    tribute.attach(document.getElementsByClassName("mention"));
  }
  else if ($(".mentioned").length > 0){
    tribute.attach(document.getElementsByClassName("mentioned"));
  }
  function getUsernames(query, callback) {
    if (query.length < 1) {
      return false;
    } else {
      return $.getJSON('/mentions', {
        q: query,
        inbox: $(".mention").data("mission-id")
      }, function(data) {
        if (data){
          callback(data);
        } else {
          callback([]);
        }
      });
    }
  }
});