/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import $ from 'jquery';
import 'materialize-css/dist/js/materialize';

var application_ready = function application_ready() {
  var carousel = $('.carousel').carousel({
    dist: 0,
    shift: 0,
    padding: 20
  });  

  $('.carousel-prev').click(function(e){
    e.preventDefault();
    $('.carousel').carousel('prev');
    var selected_profile = $('#mission_user_secret_profile_secret_profile_id').val();
    var item = $(".carousel-item[data-profile-id='".concat(selected_profile, "']")).prev();
    if (item.length < 1) {
      item = $($('.carousel-item')[$('.carousel-item').length - 1]);
    }

    var new_selected_profile = item.data('profile-id');

    return set_profile(new_selected_profile);
  });

  $('.carousel-next').click(function(e){
    e.preventDefault();
    $('.carousel').carousel('next');

    var selected_profile = $('#mission_user_secret_profile_secret_profile_id').val();
    var item = $(".carousel-item[data-profile-id='".concat(selected_profile, "']")).next();
    if (item.length < 1) {
      item = $($('.carousel-item')[0]);
    }

    var new_selected_profile = item.data('profile-id');

    return set_profile(new_selected_profile);
  });

  $('.carousel-item').on('click', function(e) {
    e.preventDefault();
    if (!!$(this).data('profile-id')) {
      return set_profile($(this).data('profile-id'));
    }
  });

  carousel.on('carouselSet', function(e, n) {
    console.log("setting value to ".concat(n));
    if ($('.carousel-item').length >= n) {
      var selected_profile = $($('.carousel-item')[n]).data('profile-id');
      $('#mission_user_secret_profile_secret_profile_id').val(selected_profile);  
    }
  });

  var set_profile = function set_profile(selected_profile) {
    if (!!!selected_profile) { return; }
    console.log("selecting: ".concat(selected_profile));
    return $('#mission_user_secret_profile_secret_profile_id').val(selected_profile);
  };

  var select_profile = function select_profile() {  
    var selected_profile = $("a.carousel-item[style*='z-index: 0;']").data('profile-id');
    return set_profile(selected_profile);
  };

  $('.save-profile').on('click', function(e){
    select_profile();
    return $( ".secret_profile_form").submit();
  });  

  return select_profile();
};


  


$(document).on('ajax:complete', application_ready);
$(document).ready(application_ready);
$(document).on('turbolinks:load' , application_ready);
