/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
// You can use CoffeeScript in this file: http://coffeescript.org/
$(document).ready(function() {

    var init_add_asr_variabel_value_click = function init_add_asr_variabel_value_click() {
        $('.add-asr-variable-value').off('click');
        return $('.add-asr-variable-value').on('click', function(e) {
            e.preventDefault();
            var asr_variable_id = $(this).data('asr-variable-id');
            var new_id = new Date().getTime();
            var regxp_asr_variable = new RegExp("asrv_index","g");
            var regxp_asr_variable_value = new RegExp("asrv_value_index","g");
            var html_val = "<tr class='existing-asr-variable-value'><td><input class='input-table-field' type='text' name='advance_selection_round[asr_variables_attributes][asrv_index][asr_variable_values_attributes][asrv_value_index][label]' id='advance_selection_round_asr_variables_attributes_asrv_index_asr_variable_values_attributes_asrv_value_index_label' /></td><td><input class='input-table-field' type='text' name='advance_selection_round[asr_variables_attributes][asrv_index][asr_variable_values_attributes][asrv_value_index][weight]' id='advance_selection_round_asr_variables_attributes_asrv_index_asr_variable_values_attributes_asrv_value_index_weight' /></td><td><input type='hidden' value='false' name='advance_selection_round[asr_variables_attributes][asrv_index][asr_variable_values_attributes][asrv_value_index][_destroy]' id='advance_selection_round_asr_variables_attributes_asrv_index_asr_variable_values_attributes_asrv_value_index__destroy' /><a class='remove-asr-variable-value' href='#'><i class='material-icons'>delete</i></a></td></tr>"; //$("#new-asr-variable-value").html()
            html_val = html_val.replace(regxp_asr_variable,asr_variable_id);
            html_val = html_val.replace(regxp_asr_variable_value,new_id);
            $(this).parent().parent().find("tbody").append(html_val);
            return init_remove_asr_variabel_value_click();
        });
    };


    var init_remove_asr_variabel_value_click = function init_remove_asr_variabel_value_click() {
        $('.remove-asr-variable-value').off('click');
        return $('.remove-asr-variable-value').on('click', function() {
            var confirmation_variable_value = confirm("Do you want to delete Variable  Value?");
            if (confirmation_variable_value === true) {
              $(this).prev("input[type=hidden]").val("1");
              return $(this).parent().parent(".existing-asr-variable-value").hide();
          }
        });
    };


    var init_remove_asr_variable_click = function init_remove_asr_variable_click() {
        $('.remove-asr-variable').off('click');
        return $('.remove-asr-variable').on('click', function() {
            var confirmation_variable = confirm("Do you want to delete Variable ?");
            if (confirmation_variable === true) {
              $(this).prev("input[type=hidden]").val("1");
              return $(this).parents(".existing-asr-variable").hide();
          }
        });
    };

    $('.add-asr-variable').on('click', function(e) {
        e.preventDefault();
        var new_id = new Date().getTime();
        var regxp = new RegExp("asrv_index","g");
        var regxp_data = new RegExp("data-asr-variable-id=\"\"","g");
        var html_val = $("#new-asr-variable").html();
        html_val = html_val.replace(regxp,new_id);
        html_val = html_val.replace(regxp_data,'data-asr-variable-id='+new_id);
        $(".asr-variables").append(html_val);

        init_add_asr_variabel_value_click();
        init_remove_asr_variabel_value_click();
        $('.collapsible').accordion();
        $(".asr-variables").find(".collapsible-header").off("keypress keydown keyup"); //enable space in input field

        return init_remove_asr_variable_click();
    });



    init_remove_asr_variable_click();

    init_add_asr_variabel_value_click();
    init_remove_asr_variabel_value_click();
    if ($('#screen_res').length > 0) {
      var screen_res = "".concat($(window).width(), "X").concat($(window).height());
      return $('#screen_res').val(screen_res);
  }
});
