$(function() {
  window.perform_action = function(action_url, selected_invite_ids, selected_action, message_subject, message_text) {
    return $.ajax({
      type: 'POST',
      url: action_url,
      data: {
        invite_ids: selected_invite_ids.toArray(),
        action_type: selected_action,
        subject: message_subject,
        message: message_text
      },
      success: function(data) {
        return init_invite_actions();
      },
      error: function(data) {
        return init_invite_actions();
      }
    });
  };
  $('.dropdown-button').dropdown({
    inDuration: 300,
    outDuration: 225,
    constrain_width: false,
    hover: true,
    gutter: 0,
    belowOrigin: false,
    alignment: 'left'
  });
  window.init_invite_actions = function() {
    $('#create-new-agent1').off('click');
    $('#create-new-agent1').on('click', function() {
      $('.add-agent-form').toggle();
      $('.search-agent').toggle();
    });
    $('ul#check-all>li').not('.title').not('.divider').on("click", function() {
      var checked_status, filter, select_all_filter;
      filter = $(this).data("filter");
      select_all_filter = $("#am-invites-select-all").data('filter');
      checked_status = !$("#am-invites-select-all").prop('checked');
      if (filter !== select_all_filter) {
        checked_status = true;
      }
      $("#am-invites-table>tbody>tr input:checkbox").prop("checked", false);
      if (filter === -1) {
        $("#am-invites-table>tbody>tr input:checkbox").prop("checked", checked_status);
      } else {
        $("#am-invites-table>tbody>tr input:checkbox[data-invite-status='" + filter + "']").prop("checked", checked_status);
      }
      if ($("#am-invites-table>tbody>tr input:checked").length > 0) {
        $("#am-invites-select-all").data('filter', filter);
        return $("#am-invites-select-all").prop('checked', true);
      } else {
        $("#am-invites-select-all").prop('checked', false);
        return $("#am-invites-select-all").data('filter', '');
      }
    });
    $(".action-dropdown").on("click", function() {
      var element;
      $("#am-invites-table>tbody>tr input:checkbox").prop("checked", false);
      element = $(this).parent().parent().find('input');
      return element.prop('checked', true);
    });
    $(".am-invites-action").off("click");
    return $(".am-invites-action").on("click", function() {
      var element, message, message_subject, selected_action, selected_invite_ids;
      selected_invite_ids = $("#am-invites-table>tbody>tr input:checked").map(function() {
        return $(this).data("invite-id");
      });
      element = $(this);
      selected_action = element.data('action');
      message_subject = $('#am-message-subject').val();
      message = $('#am-message').val();
      if (selected_action === 0) {
        if (!!message && message.length > 2) {
          if (!!message_subject && message_subject.length > 2) {
            if (selected_invite_ids.length > 0) {
              return perform_action(element.data("url"), selected_invite_ids, selected_action, message_subject, message);
            } else {
              $('.toast').remove();
              return Materialize.toast("Please select target users", 5000);
            }
          } else {
            $('.toast').remove();
            return Materialize.toast("Please enter subject with more than 3 characters", 5000);
          }
        } else {
          $('.toast').remove();
          return Materialize.toast("Please enter message with more than 3 characters", 5000);
        }
      } else {
        if (selected_invite_ids.length > 0) {
          return perform_action(element.data("url"), selected_invite_ids, selected_action, message_subject, message);
        } else {
          $('.toast').remove();
          return Materialize.toast("Please select target users", 5000);
        }
      }
    });
  };
  return init_invite_actions();
});
