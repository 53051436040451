import Tribute from "tributejs";
var calcForColumnWidth = () => {
  const windowWidth = window.innerWidth;
  const sideDrawerWidth = $(".sideDrawer").innerWidth();
  const addingColWidth = $(".addingCol").innerWidth();
  const mainColsWidth = windowWidth - (sideDrawerWidth + addingColWidth);
  $(".overflowCol").width(mainColsWidth);
};

var columnName = {
  "category-column-2": "Category B",
  "category-column-3": "Category C",
  "category-column-4": "Category D",
  "category-column-5": "Category E",
};
var tmpColDelete = [];

window.toggle_user_new_invite_select = function () {
  var dummy, id, ref;
  (ref = $(this).attr("id").split("_")),
    (dummy = ref[0]),
    (dummy = ref[1]),
    (id = ref[2]);
  if ($(this).prop("checked") === true) {
    $(this).text("check_circle");
    $(this)
      .parent()
      .append(
        "<input type='hidden' id='user-name-" +
          id +
          "' name='users[" +
          id +
          "][name]' value='" +
          $(this).data("name") +
          "'/>"
      );
    $(this)
      .parent()
      .append(
        "<input type='hidden' id='user-email-" +
          id +
          "' name='users[" +
          id +
          "][email]' value='" +
          $(this).data("email") +
          "'/>"
      );
  } else {
    $("#user-name-" + id).remove();
    $("#user-email-" + id).remove();
  }
};

function tribute_mention() {
  var outer_div = $("#idea-view-modal");
  var tribute = new Tribute({
    values: function (search, cb) {
      getUsernames(search, (users) => cb(users));
    },
    selectClass: "element-hover",
    itemClass: "main_tribute_li",
    menuItemTemplate: function (item) {
      return (
        '<img style="width:25px;height:25px;margin-left:10px;margin-right: 10px; border-radius: 50%;" src="' +
        item.original.img_url +
        '">' +
        " <span>" +
        " @" +
        item.original.key +
        "</span>"
      );
    },
  });
  if (outer_div.find(".mention").length > 0) {
    tribute.attach(outer_div.find(".mention"));
  }
}

function getUsernames(query, callback) {
  if (query.length < 1) {
    return false;
  } else {
    return $.getJSON(
      "/mentions",
      {
        q: query,
        board: $(".mention").data("mission-id"),
      },
      function (data) {
        if (data) {
          callback(data);
        } else {
          callback([]);
        }
      }
    );
  }
}

function updateCols() {
  const columnWidth = 270;
  //const columnWidth = 100;
  const latestChildern = $(".number-row").children();
  if (latestChildern.length > 4) {
    for (var i = 0; i <= latestChildern.length; i++) {
      const ele = latestChildern[i];
      if (ele) {
        $(ele).addClass("boards-category-column");
      }
      $(ele).fadeIn(1000);
    }
  } else {
    for (var i = 0; i <= latestChildern.length; i++) {
      const ele = latestChildern[i];
      if (ele) {
        $(ele).addClass("boards-category-column");
      }
      $(ele).fadeIn(1000);
    }
  }
}

document.addEventListener("DOMContentLoaded", () => {
  board_ready();
  tribute_mention();
  if ($("#mission_funnel_id").val() == "") {
    $(".modal").modal();
    return setTimeout(function () {
      $("#setting-modal.modal").modal("open");
    }, 50);
  }
});

window.board_ready = function () {
  $(document).mouseup(function (e) {
    var container = $("#setting-modal.modal");
    if (!container.is(e.target) && container.has(e.target).length === 0) {
      if ($("#mission_funnel_id").val() == "") {
        $(".toast").remove();
        Materialize.toast("Please select funnel", 2000);
        $(".modal").modal();
        return setTimeout(function () {
          if ($("#setting-modal").length > 0) {
            $("#setting-modal.modal").modal("open");
          }
        }, 50);
      }
      // else if ($("#mission_department_id").val() == "") {
      //   $(".toast").remove();
      //   //Materialize.toast("Department can not be empty!.", 2000);
      //   $(".modal").modal();
      //   return setTimeout(function () {
      //     if ($("#setting-modal").length > 0) {
      //       $("#setting-modal.modal").modal("open");
      //     }
      //   }, 50);
      // }
    }
  });

  $(document).on("click", "#myCheckbox-main-Section1", function (e) {
    if (this.checked === false) {
      $("#mission-table-Section1 input:checkbox:checked").each(function () {
        this.checked = false;
      });
    } else {
      $("#mission-table-Section1 input:visible:checkbox:not(:checked)").each(
        function () {
          this.checked = true;
        }
      );
    }
  });

  $("#myCheckbox-main-Section-manage-agents").on("click", function () {
    if (this.checked === false) {
      $("#mission-table-Section-manage-agents input:checkbox:checked").each(
        function () {
          this.checked = false;
        }
      );
    } else {
      $(
        "#mission-table-Section-manage-agents input:visible:checkbox:not(:checked)"
      ).each(function () {
        this.checked = true;
      });
    }
  });

  $("#mission-table-Section1").tablesorter();
  $("#mission-table-Section2").tablesorter();

  $(".edit-board-category").off("click");
  $(".edit-board-category").click(function () {
    var category_value = $(this).parent().data("category");
    $(this).parent().next().css("display", "flex");
    $(this).parent().next().css("align-items", "center");
    $(this).parent().next().find(".category_title").val(category_value);
    $(this).parent().css("display", "none");
  });

  $(".close-category").off("click");
  $(".close-category").click(function () {
    $(this).parent().css("display", "none");
    $(this).parent().parent().find(".category-drag").css("display", "flex");
  });

  $(".board-idea-item").off("click");
  $(".board-idea-item").click(function () {
    event.stopPropagation();
  });

  $("#copy-to-inbox-select-tag").off("change");
  $("#copy-to-inbox-select-tag").change(function () {
    var inbox_id = $(this).val();
    var selected_ids;
    var board_id = $("#board_id").val();
    var action_url = "/boards/" + board_id + "/ideas/copy_to_inbox";
    selected_ids = $(".card-check:checked").map(function () {
      return $(this).data("idea-id");
    });
    return $.ajax({
      type: "POST",
      url: action_url,
      data: {
        board: { inbox_id: inbox_id, idea_ids: selected_ids.toArray() },
      },
      success: function (data) {
        Materialize.toast("Ideas are copied successfully", 2000);
        return window.location.reload();
      },
      error: function (data) {},
    });
  });

  $(".add-idea-icon").off("click");
  $(".add-idea-icon").on("click", function (e) {
    var text, comment_url;
    text = $(this)
      .parent()
      .parent()
      .children(".category-title")
      .children(".category-drag")
      .children(".title")
      .text();
    e.preventDefault();
    comment_url = $(".idea-add").data("url");
    return $.ajax({
      type: "GET",
      url: comment_url,
      dataType: "script",
      data: { text_value: text },
    });
  });

  $("#board_action_dropdown").off("change");
  $("#board_action_dropdown").change(function () {
    if ($(this).val() != "") {
      var selected_ids;
      var board_id = $("#board_id").val();
      selected_ids = $(".card-check:checked").map(function () {
        return $(this).data("idea-id");
      });
      var all_deletable = $(".card-check:checked").map(function () {
        return $(this).data("deletable");
      });
      if (selected_ids.length > 0) {
        if ($(this).val() == 0) {
          $("#copy-to-inbox-list").modal("open");
        } else if ($(this).val() == 1) {
          if (confirm("Are you sure want to delete?")) {
            ideas_actions(
              "/boards/" + board_id + "/ideas/delete_board_ideas",
              selected_ids,
              "DELETE"
            );
          }
          // if (all_deletable.toArray().includes(false)) {
          //   Materialize.toast(
          //     "You have selected ideas of other users, you cant delete those",
          //     5000
          //   );
          // } else if (confirm("Are you sure want to delete?")) {
          //   ideas_actions(
          //     "/boards/" + board_id + "/ideas/delete_board_ideas",
          //     selected_ids,
          //     "DELETE"
          //   );
          // }
        }
      } else Materialize.toast("Please select items first", 5000);
    }
  });

  $(".edit-board-title").off("click");
  $(".edit-board-title").click(function () {
    var title_value = $(this).parent().find("h6").data("title");
    $(this).parent().next().css("display", "block");
    $(this).parent().next().find(".board_title").val(title_value);
    $(this).parent().css("display", "none");
  });

  $(".sidenav-edit-board-description").off("click");
  $(".sidenav-edit-board-description").click(function () {
    $(this).parent().next().css("display", "block");
    $(this).parent().css("display", "none");
  });

  $(".sidenav-edit-board-title").off("click");
  $(".sidenav-edit-board-title").click(function () {
    title_value = $(this).parent().find("p").data("sidenav-board-title");
    $(this).parent().next().css("display", "block");
    $(this).parent().next().find(".sidenav_board_title").val(title_value);
    $(this).parent().css("display", "none");
  });

  $(".save-close").off("click");
  $(".save-close").click(function () {
    if ($("#mission_funnel_id").val() == "") {
      $(".toast").remove();
      Materialize.toast("Funnel can not be empty.", 2000);
      $(".modal").modal();
      return setTimeout(function () {
        if ($("#setting-modal").length > 0) {
          $("#setting-modal.modal").modal("open", {
            dismissible: false,
          });
        }
      }, 50);
    }
    //  else if ($("#mission_department_id").val() == "") {
    //   $(".toast").remove();
    //   Materialize.toast("Department can not be empty.", 2000);
    //   $(".modal").modal();
    //   return setTimeout(function () {
    //     if ($("#setting-modal").length > 0) {
    //       $("#setting-modal.modal").modal("open", {
    //         dismissible: false,
    //       });
    //     }
    //   }, 50);}
    else {
      $(".edit_board_form").submit();
      setTimeout(function() {
        window.location.reload();
      }, 1000); // Reload after 1 second (1000 milliseconds)
    }
  });

  $(".sidenav_board_title").off("change");
  $(".sidenav_board_title").change(function () {
    element = $(this);
    $.ajax({
      type: "PUT",
      url: element.data("url"),
      data: {
        board: { title: element.val() },
      },
      success: function (data) {
        element
          .parent()
          .parent()
          .find(".sidenav-board-title")
          .css("display", "flex");
        element.parent().css("display", "none");
        element
          .parent()
          .parent()
          .find(".sidenav-board-title")
          .find("p")
          .text(element.val());
      },
      error: function (data) {},
    });
  });

  $(".save-sidenav-board-description").off("click");
  $(".save-sidenav-board-description").click(function () {
    element = $(this);
    value = $("#board-description").val();
    $.ajax({
      type: "PUT",
      url: element.data("url"),
      data: {
        description: value,
      },
      success: function (data) {
        element
          .parent()
          .parent()
          .find(".sidenav-board-description")
          .css("display", "flex");
        element.parent().css("display", "none");
        element
          .parent()
          .parent()
          .find(".sidenav-board-description")
          .find("span")
          .text(value);
      },
      error: function (data) {},
    });
  });

  $(".edit-round-title").off("click");
  $(".edit-round-title").click(function () {
    title_value = $(this).parent().find("h6").data("title");
    $(this).parent().next().css("display", "block");
    $(this).parent().next().find(".round_title").val(title_value);
    $(this).parent().css("display", "none");
  });

  $(".category_title").off("change");
  $(".category_title").change(function () {
    var element = $(this);
    var mission_board_id = $("#board_id").val();
    $.ajax({
      type: "PUT",
      url: element.data("url"),
      data: {
        title: element.val(),
        board_id: mission_board_id,
      },
      success: function (data) {
        element.parent().parent().find(".category-drag").css("display", "flex");
        element.parent().css("display", "none");
        element
          .parent()
          .parent()
          .find(".category-drag")
          .data("category", element.val());
        element
          .parent()
          .parent()
          .find(".category-drag")
          .find(".title")
          .text(element.val());
        element
          .parent()
          .parent()
          .find(".category-drag")
          .find("h6")
          .addClass("truncate-column-text");
      },
      error: function (data) {},
    });
  });

  $(".round_title").off("change");
  $(".round_title").change(function () {
    var element = $(this);
    mission_board_id = $("#board_id").val();
    $.ajax({
      type: "PUT",
      url: element.data("url"),
      dataType: "script",
      data: { round: { title: element.val() }, board_id: mission_board_id },
      success: function (data) {
        element
          .parent()
          .parent()
          .find(".category-heading")
          .css("display", "flex");
        element.parent().css("display", "none");
        element
          .parent()
          .parent()
          .find(".category-heading")
          .find("h6")
          .text(element.val());
      },
      error: function (data) {},
    });
  });

  $(".sortable-cats").sortable({
    //connectWith: '.overflowCol',
    tolerance: "pointer",
    start: function (event, ui) {
      $(ui.item).removeClass("boards-category-column");
      $(ui.item).css("width", "100px");
    },
    stop: function (event, ui) {
      $(ui.item).addClass("boards-category-column");
      var all_elements,
        board_id,
        i,
        idea_id,
        cat_with_position,
        cats_with_positions;
      board_id = $("#board_id").val();
      cats_with_positions = [];
      all_elements = ui.item.parent().children();
      i = 0;
      while (i < all_elements.length) {
        cat_with_position = [];
        cat_with_position.push(i);
        cat_with_position.push(all_elements[i].dataset.id);
        cats_with_positions.push(cat_with_position);
        i++;
      }
      return $.ajax({
        type: "POST",
        url: "/boards/" + board_id + "/sort_board_cats",
        data: {
          id: board_id,
          cats_with_positions: cats_with_positions,
        },
        success: function (data) {},
      });
    },
  });

  $(".sortable-ideas").sortable({
    connectWith: ".idea-column-body",
    placeholder: "placeholder",
    tolerance: "pointer",
    scroll: true,
    stop: function (event, ui) {
      var all_elements,
        board_id,
        i,
        idea_id,
        idea_with_position,
        ideas_with_positions,
        category_id;
      category_id = ui.item.parent().data("category-id");
      idea_id = ui.item.data("idea-id");
      board_id = $("#board_id").val();
      ideas_with_positions = [];
      all_elements = ui.item.parent().children();
      i = 0;
      while (i < all_elements.length) {
        idea_with_position = [];
        idea_with_position.push(i);
        idea_with_position.push(all_elements[i].dataset.ideaId);
        ideas_with_positions.push(idea_with_position);
        i++;
      }
      return $.ajax({
        type: "POST",
        url: "/boards/" + board_id + "/sort_board_ideas",
        data: {
          category_id: category_id,
          idea_id: idea_id,
          ideas_with_positions: ideas_with_positions,
        },
        success: function (data) {},
      });
    },
  });

  $(".sort-icon").off("click");
  $(".sort-icon").on("click", function () {
    var all_elements,
      board_id,
      i,
      idea_id,
      category_text,
      ideas_with_positions,
      category_id,
      category_text;
    category_id = $(".board-li").data("category-id");
    idea_id = $(".board-li").data("idea-id");
    board_id = $("#board_id").val();
    category_text = $(this)
      .first()
      .parent()
      .parent()
      .children(".category-drag")
      .find(".title")
      .text();
    ideas_with_positions = [];
    all_elements = $(this)
      .parents(".category-column-width")
      .find(".sortable-ideas li");
    i = 0;
    while (i < all_elements.length) {
      ideas_with_positions.push(all_elements[i].dataset.ideaId);
      i++;
    }
    return $.ajax({
      type: "POST",
      url: "/boards/" + board_id + "/sort_idea_on_likes",
      data: {
        sort_params: "sort_desc",
        category_id: category_id,
        category_text: category_text,
        idea_id: idea_id,
        ideas_with_positions: ideas_with_positions,
      },
      success: function (data) {},
    });
  });

  $(".reverse_sort").off("click");
  $(".reverse_sort").on("click", function () {
    var all_elements,
      board_id,
      i,
      idea_id,
      category_text,
      ideas_with_positions,
      category_id,
      category_text;
    category_id = $(".board-li").data("category-id");
    idea_id = $(".board-li").data("idea-id");
    board_id = $("#board_id").val();
    category_text = $(this)
      .first()
      .parent()
      .parent()
      .children(".category-drag")
      .find(".title")
      .text();
    ideas_with_positions = [];
    all_elements = $(this)
      .parents(".category-column-width")
      .find(".sortable-ideas li");
    i = 0;
    while (i < all_elements.length) {
      ideas_with_positions.push(all_elements[i].dataset.ideaId);
      i++;
    }
    return $.ajax({
      type: "POST",
      url: "/boards/" + board_id + "/sort_idea_on_likes",
      data: {
        sort_params: "sort_asc",
        category_id: category_id,
        category_text: category_text,
        idea_id: idea_id,
        ideas_with_positions: ideas_with_positions,
      },
      success: function (data) {},
    });
  });

  $(".board_title").off("change");
  $(".board_title").change(function () {
    var element = $(this);
    $.ajax({
      type: "PUT",
      url: element.data("url"),
      data: {
        //board: {title: element.val()}
        mission: { title: element.val() },
      },
      success: function (data) {
        element
          .parent()
          .parent()
          .find(".category-heading")
          .css("display", "flex");
        element.parent().css("display", "none");
        element
          .parent()
          .parent()
          .find(".category-heading")
          .find("h6")
          .text(element.val());
        element
          .parent()
          .parent()
          .find(".category-heading")
          .find("h6")
          .addClass("truncate-text");
      },
      error: function (data) {},
    });
  });

  $(".update-status-checkbox").off("change");
  $(".update-status-checkbox").on("change", function () {
    $(this).parents("form:first").submit();
  });

  $(".remove-agents-btn").off("click");
  $(".remove-agents-btn").on("click", function () {
    var board_id, selected_ids;
    selected_ids = $(".filled-in:checkbox:checked").map(function () {
      return $(this).val();
    });
    if (selected_ids.length > 0) {
      if (
        confirm(
          "The agents you have selected will be removed from the board.\n Do you want to continue?"
        )
      ) {
        board_id = $("#board_id").val();
        $.ajax({
          type: "DELETE",
          url: "/boards/" + board_id + "/board_invites/remove_board_invites",
          data: {
            invite_ids: selected_ids.toArray(),
          },
          success: function (data) {},
          error: function (data) {},
        });
      }
    } else {
      return Materialize.toast("You need to select agents first", 5000);
    }
  });

  $("#board_agent_actions").off("change");
  $("#board_agent_actions").on("change", function (e) {
    var force_accept_path, root_path, board_action, selected_ids;
    //$(document).find('#mission-table-Section1').remove()
    if (
      $("#mission-table-Section5 input:checkbox:checked:visible").length > 0
    ) {
      selected_ids = $("#mission-table-Section5 input:checkbox:checked").map(
        function () {
          return $(this).val();
        }
      );
      board_action = $("#board_agent_actions").val();
      board_id = $("#board_id").val();
      if ($("#board_agent_actions").val() === "0") {
        //return $('#modal-send-message').modal('open');
      } else if ($("#board_agent_actions").val() === "1") {
        return remove_selection_agents(
          "/boards/" + board_id + "/board_invites/resend_invites",
          selected_ids.toArray(),
          "POST"
        );
      } else if ($("#board_agent_actions").val() === "2") {
        return remove_selection_agents(
          "/boards/" + board_id + "/board_invites/force_accept_all",
          selected_ids.toArray(),
          "POST"
        );
      } else if ($("#board_agent_actions").val() === "3") {
        if (
          confirm(
            "The agents you have selected will be removed from the inbox.\n Do you want to continue?"
          )
        )
          return remove_selection_agents(
            "/boards/" + board_id + "/board_invites/remove_board_invites",
            selected_ids.toArray(),
            "DELETE"
          );
      }
    } else {
      return Materialize.toast("Please Select Agents.", 2000);
    }
  });

  $(document).on("keyup", "#search-participant", function () {
    var filter;
    filter = $(this).val();
    $("table#mission-table-Section2 tbody tr")
      .not(":contains(" + filter + ")")
      .fadeOut();
    $("table#mission-table-Section2 tbody tr:contains(" + filter + ")").show();
  });

  $("#add-board-invite").off("click");
  $("#add-board-invite").on("click", function () {
    var cell1, cell2, cell3, cell4, elems, email, id, length, name, row;
    name = $("#Name").val();
    email = $("#Email").val().toLowerCase();
    email = email.toLowerCase();
    if (!!name && !!email) {
      console.log(name + " " + email);
      $("#Name").val("");
      $("#Email").val("");
      id = length = $("#mission-table-Section2 tr").length;
      row = $("#mission-table-Section2").find("tbody")[0].insertRow();
      row.className = "table-row";
      cell1 = row.insertCell(0);
      cell2 = row.insertCell(1);
      cell3 = row.insertCell(2);
      cell4 = row.insertCell(3);
      cell2.innerHTML = name;
      cell3.className = "center";
      cell3.innerHTML = email;
      cell4.className = "center";
      cell4.innerHTML = "Pending";
      elems = [
        "<input class='filled-in user-board-invite-new-add' id='checkbox_section1_" +
          id +
          "' type='checkbox' value='" +
          id +
          "' data-name='" +
          name +
          "' data-email='" +
          email +
          "' checked='checked' style='opacity: 0 !important'><label class='table-checkbox' for='checkbox_section1_" +
          id +
          "'></label> ",
      ];
      elems.push(
        "<input type='hidden' id='user-name-" +
          id +
          "' name='users[" +
          id +
          "][name]' value='" +
          name +
          "'/>"
      );
      elems.push(
        "<input type='hidden' id='user-email-" +
          id +
          "' name='users[" +
          id +
          "][email]' value='" +
          email +
          "'/>"
      );
      cell1.innerHTML = elems.join("");
      return $("#checkbox_section1_" + id).click(toggle_user_new_invite_select);
    } else {
      $(".toast").remove();
      return Materialize.toast("Please enter Name and Email.", 2000);
    }
  });

  $(".user-board-invite-new-add").off("click");
  $(".user-board-invite-new-add").click(toggle_user_new_invite_select);

  $(".like-board-idea").off("click");
  $(".like-board-idea").click(function (e) {
    e.stopPropagation();
    var board_id, id, idea, inbox_id, mission, url;
    inbox_id = $("#mission_id").val();
    board_id = $("#board_id").val();
    id = $("#idea_id").val();
    if (!inbox_id && !id) {
      mission = this.getElementsByClassName("mission_id")[0];
      idea = this.getElementsByClassName("idea_id")[0];
      if (!!mission) {
        inbox_id = mission.value;
      }
      if (!!idea) {
        id = idea.value;
      }
    }
    if (!!inbox_id) {
      url = "/inbox/" + inbox_id + "/ideas/" + id + "/like_idea";
    } else {
      url = "/boards/" + board_id + "/ideas/" + id + "/like_idea";
    }
    return $.ajax({
      type: "POST",
      url: url,
      data: {},
      success: function (data) {},
    });
  });

  $(".delete-round").off("click");
  $(".delete-round").click(function () {
    number_of_items = $("#items-number").val();
    if (number_of_items >= 1) {
      if (
        confirm("This Round has items, do you really want to delete ROUND?")
      ) {
      }
    }
  });

  var readURL;

  readURL = function (input) {
    var reader;
    if (input.files && input.files[0]) {
      reader = new FileReader();
      reader.onload = function (e) {
        $("#img_prev_settings").css(
          "background-image",
          "url(" + e.target.result + ")"
        );
      };
      reader.readAsDataURL(input.files[0]);
    }
  };

  $(".board-settings").change(function () {
    $("#img_prev_settings").removeClass("hidden");
    readURL(this);
  });

  var readURLNew;

  readURLNew = function (input) {
    var reader;
    if (input.files && input.files[0]) {
      reader = new FileReader();
      reader.onload = function (e) {
        $("#img_prev_new").css(
          "background-image",
          "url(" + e.target.result + ")"
        );
      };
      reader.readAsDataURL(input.files[0]);
    }
  };

  $(".idea-image-new").change(function () {
    $("#img_prev_new").removeClass("hidden");
    readURLNew(this);
  });

  var readURLEdit;

  readURLEdit = function (input) {
    var reader;
    if (input.files && input.files[0]) {
      reader = new FileReader();
      reader.onload = function (e) {
        $("#img_prev_edit").css(
          "background-image",
          "url(" + e.target.result + ")"
        );
      };
      reader.readAsDataURL(input.files[0]);
    }
  };

  $(".idea-image-edit").change(function () {
    $("#img_prev_edit").removeClass("hidden");
    readURLEdit(this);
  });

  $(".category_item").off("click");
  $(".category_item").on("click", function (e) {
    var element;
    e.preventDefault();
    element = $(this);
    $(this).find(".idea_id").val(element.data("id"));
    return $.ajax({
      url: element.data("view-url"),
      data: {},
      success: function (data) {
        $("#idea-view-modal").find(".idea-view-content").html(data);
        $(".modal").modal();
        return setTimeout(function () {
          if ($("#card-description").length > 0) {
            $("#add-idea").css("width", "80%");
            $("#card-description.modal").modal("open");
          }
          return $("select").material_select();
        }, 50);
      },
      complete: function () {
        tribute_mention();
      },
    });
  });

  $("#board_users").off("change");
  $("#board_users").change(function () {
    var element = $(this);
    board_id = $("#board_id").val();
    return $.ajax({
      url: "/boards/" + board_id + "/user_filter",
      data: { user_id: element.val() },
      success: function (data) {
        return;
      },
    });
  });

  $(".category_item_comments").off("click");
  $(".category_item_comments").on("click", function (e) {
    e.stopPropagation();
    var element;
    e.preventDefault();
    element = $(this);
    $(this).find(".idea_id").val(element.data("id"));
    return $.ajax({
      url: element.data("view-url"),
      data: {},
      success: function (data) {
        $("#idea-view-modal").find(".idea-view-content").html(data);
        $(".modal").modal();
        return setTimeout(function () {
          if ($("#card-description").length > 0) {
            $("#card-description.modal").modal("open");
          }
          return $("select").material_select();
        }, 50);
      },
    });
  });

  $(".add-column-button").off("click");
  $(".add-column-button").on("click", function () {
    document.querySelector("#addingCategory").click()
  });

  $(".view-board-from-setting").off("click");
  $(".view-board-from-setting").click(function () {
    $(".list-div").hide();
    $("#categories_columns").show();
    $(".baords-list-view i").text("view_list");
    $("#setting-modal").modal("close");
  });

  $(".baords-list-view").off("click");
  $(".baords-list-view").click(function () {
    if ($(".baords-list-view i").text().trim() == "view_list") {
      $(".list-div").show();
      $("#categories_columns").hide();
      $(".baords-list-view i").text("view_module");
    } else {
      $(".list-div").hide();
      $("#categories_columns").show();
      $(".baords-list-view i").text("view_list");
    }
  });

  $(".addingCol").off("click");
  $(".addingCol").click(function () {
    const allowedColumn = 30;
    const parentForAppendNewCol = $(".number-row");
    const parentForAppendNewColChildern = parentForAppendNewCol.children();
    var length = parentForAppendNewColChildern.length;
    const currentIndex = length;
    let columnId = `category-column-${currentIndex + 1}`;
    if (tmpColDelete.length) {
      columnId = tmpColDelete[0].split("#")[1];
    }
    const column = `<div data-id="${
      currentIndex + 1
    }" id="${columnId}"style="padding:0;height:100%;display:none;border-right:1px solid #cccccc;" class="dynamic-col"><hr><div class="category-title"><div></div><div class="category-drag"><h6>${
      columnName[columnId]
    }</h6><i class="material-icons tiny">edit</i><button class="material-icons delete tiny" data-remove-column="#${columnId}" style="padding: 0;margin: 0;border: 0;background: transparent;font-size: 1rem;">delete</button></div><div class="category-number"><p>0</p></div></div><hr><div class="row"><div class="col s3 m3"><div class="card" style="width:255px ;"><div class="card-image" style="height:70px"><input checked="" class="card-check" type="checkbox"><img src="https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__340.jpg"><span class="card-title" style="left:0%; padding:0;width:100%;background-color:#0000007d; font-size:16px">Slider Orange</span></div><div class="card-content" style="padding:20px;padding-bottom:10px;padding-right:10px;padding-left:10px"><div class="cateogry-card-content"><div class="category-img"><img class="responsive-img" src="https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__340.jpg"><div class="category-name" style="padding-left:10px"></div><div><p> John Doe</p><p style="font-size:12px">30/9/2020</p></div></div><div class="category-likes"><div><i class="material-icons tiny">thumb_up</i><p>0</p></div><div style="padding:0"><i class="material-icons tiny"> comment</i><p>0</p></div></div></div></div></div></div></div></div>`;
    if (length < allowedColumn) {
      //parentForAppendNewCol.append(column);
      updateCols();
      $.ajax({
        type: "POST",
        url: $("#addingCategory").data("url"),
        data: {
          title: "Untitled",
        },
        success: function (data) {},
        error: function (data) {},
      });
    } else {
      alert("Maximum limit of categories reached");
    }
    tmpColDelete.shift(`#${columnId}`);
    console.log(tmpColDelete);
  });
};
$(function () {
  $(document).on("click", "button.delete", function () {
    const data = $(this).data();
    $(data.removeColumn).remove();
    tmpColDelete.push(data.removeColumn);
    updateCols();
  });
  calcForColumnWidth();
});

$(window).on("resize", function () {
  calcForColumnWidth();
});
var remove_selection_agents = function (url, selected_ids, type) {
  return $.ajax({
    type: type,
    url: url,
    data: {
      invite_ids: selected_ids,
    },
    success: function (data) {},
    error: function (data) {},
  });
};
var ideas_actions;
ideas_actions = function (action_url, selected_mission_ids, type) {
  return $.ajax({
    type: type,
    dataType: "script",
    url: action_url,
    data: {
      ideas_ids: selected_mission_ids.toArray(),
    },
    success: function (data) {
      Materialize.toast("Ideas deleted successfully", 2000);
      //return window.location.reload();
    },
    error: function (data) {
      window.location.reload();
      //return Materialize.toast('Problem in Deleting', 2000);
    },
  });
};
