import confetti from 'canvas-confetti';

console.log('Confetti imported:', confetti);

document.addEventListener('DOMContentLoaded', () => {
  console.log('DOM fully loaded and parsed');
  const button = document.getElementById('preview-confetti-button');
  var celebration;
  // Function to get URL parameter value
  function getUrlParameter(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
  }
  celebration = getUrlParameter('celebration');
  if (celebration != '')
  {
    runConfetti()
  }
  function triggerConfetti() {
    var button = document.getElementById('preview-confetti-button');
    celebration = button.dataset.confettiType
    runConfetti()
  }
  // Get the "celebration" parameter value
  if (button) {
    button.addEventListener('click', triggerConfetti);
  }
  function runConfetti(){
    switch (celebration) {
      case 'basic':
        confetti({
          particleCount: 100,
          spread: 70,
          origin: { y: 0.6 }
        });
      case 'random':
        function randomInRange(min, max) {
          return Math.random() * (max - min) + min;
        }
        
        confetti({
          angle: randomInRange(55, 125),
          spread: randomInRange(50, 70),
          particleCount: randomInRange(50, 100),
          origin: { y: 0.6 }
        });
      case 'realistic':
        var count = 200;
        var defaults = {
          origin: { y: 0.7 }
        };

        function fire(particleRatio, opts) {
          confetti({
            ...defaults,
            ...opts,
            particleCount: Math.floor(count * particleRatio)
          });
        }

        fire(0.25, {
          spread: 26,
          startVelocity: 55,
        });
        fire(0.2, {
          spread: 60,
        });
        fire(0.35, {
          spread: 100,
          decay: 0.91,
          scalar: 0.8
        });
        fire(0.1, {
          spread: 120,
          startVelocity: 25,
          decay: 0.92,
          scalar: 1.2
        });
        fire(0.1, {
          spread: 120,
          startVelocity: 45,
        });
      case 'fireworks':
        var duration = 5 * 1000;
        var animationEnd = Date.now() + duration;
        var defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

        function randomInRange2(min, max) {
          return Math.random() * (max - min) + min;
        }

        var interval = setInterval(function() {
          var timeLeft = animationEnd - Date.now();

          if (timeLeft <= 0) {
            return clearInterval(interval);
          }

          var particleCount = 50 * (timeLeft / duration);
          // since particles fall down, start a bit higher than random
          confetti({ ...defaults, particleCount, origin: { x: randomInRange2(0.1, 0.3), y: Math.random() - 0.2 } });
          confetti({ ...defaults, particleCount, origin: { x: randomInRange2(0.7, 0.9), y: Math.random() - 0.2 } });
        }, 250);
        break;
      
      case 'stars':
        var defaults = {
          spread: 360,
          ticks: 50,
          gravity: 0,
          decay: 0.94,
          startVelocity: 30,
          colors: ['FFE400', 'FFBD00', 'E89400', 'FFCA6C', 'FDFFB8']
        };
        
        function shoot() {
          confetti({
            ...defaults,
            particleCount: 40,
            scalar: 1.2,
            shapes: ['star']
          });
        
          confetti({
            ...defaults,
            particleCount: 10,
            scalar: 0.75,
            shapes: ['circle']
          });
        }
        
        setTimeout(shoot, 0);
        setTimeout(shoot, 100);
        setTimeout(shoot, 200);

        break;
      case 'snow':
        var duration = 5 * 1000;
        var animationEnd = Date.now() + duration;
        var skew = 1;

        function randomInRange3(min, max) {
          return Math.random() * (max - min) + min;
        }

        (function frame() {
          var timeLeft = animationEnd - Date.now();
          var ticks = Math.max(200, 500 * (timeLeft / duration));
          skew = Math.max(0.8, skew - 0.001);

          confetti({
            particleCount: 1,
            startVelocity: 0,
            ticks: ticks,
            origin: {
              x: Math.random(),
              // since particles fall down, skew start toward the top
              y: (Math.random() * skew) - 0.2
            },
            colors: ['#ffffff'],
            shapes: ['circle'],
            gravity: randomInRange3(0.4, 0.6),
            scalar: randomInRange3(0.4, 1),
            drift: randomInRange3(-0.4, 0.4)
          });

          if (timeLeft > 0) {
            requestAnimationFrame(frame);
          }
        }());

        break;
      case 'school_pride':
        var end = Date.now() + (5 * 1000);

        var colors = ['#bb0000', '#ffffff'];

        (function frame() {
          confetti({
            particleCount: 2,
            angle: 60,
            spread: 55,
            origin: { x: 0 },
            colors: colors
          });
          confetti({
            particleCount: 2,
            angle: 120,
            spread: 55,
            origin: { x: 1 },
            colors: colors
          });

          if (Date.now() < end) {
            requestAnimationFrame(frame);
          }
        }());
        break;

      default:
        console.log('No celebration type matched');
    }
  }
});
