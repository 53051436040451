var add_file_function,
  date_to_string,
  disable_default_form_submit,
  display_idea_text,
  get_end_date,
  init_date_picker,
  init_time_picker,
  mission_image,
  show_notice_toast,
  submit_idea_function;

import $ from "jquery";

import { tinymce, tinymce_settings } from "../shared/tinymce";

import Tribute from "tributejs";

import Cropper from 'cropperjs';


import "materialize-css/dist/js/materialize";

import "webpack-jquery-ui/draggable";

import "webpack-jquery-ui/droppable";

import "jquery-ui-touch-punch";

require("webpack-jquery-ui/accordion");

import "kbw-countdown/dist/js/jquery.plugin";

import "kbw-countdown/dist/js/jquery.countdown";

import "../frontend/ideas";

import "../admin/confetti";

import "../frontend/review";

import "../frontend/score_types";

import "../frontend/missions";

import "../frontend/mission_builder";

import "../frontend/mission_preview";

import "../frontend/mission_team_memberships";

import "../frontend/mission_teams";

import "../frontend/mission_user_secret_profiles";

import "../frontend/advance_selection_rounds";

import "../frontend/brain_storm_rounds";

import "../frontend/brief_rounds";

import "../frontend/break_rounds";

import "../frontend/common";

import "../frontend/choose_identity";

import "../frontend/comments";

import "../frontend/companies";

import "../frontend/invite_rounds";

import "../frontend/invites";

import "../frontend/rounds";

import "../frontend/users";

import "../frontend/selections";

import "../frontend/boards";
// import "../frontend/category";

import "../frontend/tribute_user";

import "../frontend/tribute_user_description";

import "../frontend/tribute_user_reply";
import "easy-autocomplete/dist/jquery.easy-autocomplete.js";
//import "stylesheets/application.css"

import { start_timers } from "../frontend/common";

window.jQuery = $;

window.Zepto = void 0;

window.is_idea_submitting = false;

mission_image = function () {
  $(".mission-preview-image:visible").off("change");
  return $(".mission-preview-image:visible").on("change", function () {
    return Materialize.toast(
      "Please save the misssion to preview the image.",
      2000
    );
  });
};

date_to_string = function (date) {
  var dd, hh, mins, mm, yyyy;
  yyyy = date.getFullYear().toString();
  mm = (date.getMonth() + 1).toString();
  dd = date.getDate().toString();
  hh = date.getHours().toString();
  mins = date.getMinutes().toString();
  return (
    yyyy +
    "-" +
    (mm[1] ? mm : "0" + mm[0]) +
    "-" +
    (dd[1] ? dd : "0" + dd[0]) +
    " " +
    (hh[1] ? hh : "0" + hh[0]) +
    ":" +
    (mins[1] ? mins : "0" + mins[0])
  );
};

get_end_date = function (start_date, days, hours_minutes) {
  var date, days_int, hours_int, minutes_int, time_parts;
  time_parts = hours_minutes.split(":");
  date = new Date(parseFloat(start_date));
  days_int = parseInt(days, 10);
  hours_int = parseInt(time_parts[0], 10);
  minutes_int = parseInt(time_parts[1], 10);
  date.setDate(date.getDate() + days_int);
  date.setHours(date.getHours() + hours_int);
  date.setMinutes(date.getMinutes() + minutes_int);
  return date;
};

init_time_picker = function () {
  $(".timepicker").pickatime({
    default: "now",
    twelvehour: false,
    donetext: "OK",
    showClearBtn: false,
    autoclose: true,
    vibrate: true,
    container: "body",
  });
  $("input.timepicker").mousedown(function (e) {
    var me;
    e.preventDefault();
    me = this;
    return setTimeout(function () {
      $(".timepicker:visible").click();
    }, 500);
  });
};

init_date_picker = function () {
  return $(".datepicker").pickadate({
    format: "dd/mm/yyyy",
    selectMonths: true,
    selectYears: 15,
    firstDay: 1,
    onSet: function (arg) {
      if ("select" in arg) {
        if (!!$(this).close) {
          $(this).close();
        }
      }
    },
  });
  $("input.datepicker").mousedown(function (e) {
    var me;
    e.preventDefault();
    me = this;
    return setTimeout(function () {
      $(".datepicker:visible").click();
    }, 500);
  });
};

disable_default_form_submit = function () {
  $(".ajax-submit").on("submit", function (event) {
    event.preventDefault();
  });
};

show_notice_toast = function () {
  var element;
  element = $(".notice");
  if (element && !!element.text()) {
    Materialize.toast(element.text(), 5000);
  }
  element.text("");
};

display_idea_text = function () {
  $("#idea_title").hide();
  $("#idea_content").show();
  $("#display-idea-text").hide();
  return $("#add-idea").show();
};

submit_idea_function = function () {
  var idea_content, idea_title, screen_res, selected_choices;
  if ($("#qa_round").length > 0) {
    $(".question-check:checked").siblings(".answer_choice_answer").text();
    selected_choices = [];
    $(".question-check:checked").each(function () {
      return selected_choices.push($(this).val());
    });
    idea_title = selected_choices.join(", ");
    idea_content = $("#mcq_justification").val();
    $("#idea_title").val(idea_title);
    $("#idea_content").val(idea_content);
  }
  if ($("#idea_title").val() === "") {
    Materialize.toast("Idea title needs to be filled.", 2000);
    window.is_idea_submitting = false;
  } else if (!window.is_idea_submitting) {
    screen_res = $(window).width() + "X" + $(window).height();
    $("#screen_res").val(screen_res);
    window.is_idea_submitting = true;
    Appcues.track("idea added");
    $("form.idea_submission_form").submit();
  }
};

add_file_function = function () {
  $("#idea_image").click();
};

$(document).on("click", ".submit-idea-from-icon", function (e) {
  e.preventDefault();
  if ($(".idea_title_from_icon").val() === "") {
    $(".toast").remove();
    return Materialize.toast("Idea title needs to be filled.", 2000);
  } else {
    // Appcues.track("idea added");
    return $("form.idea_submission_form_icon").submit();
  }
});

window.frontend_ready = function () {
  if ($("#current-round-timer-ideabox-2").length > 0) {
    var newtime = new Date(
      parseFloat($("#current-round-timer-ideabox-2")[0].value)
    );
    var diff = (newtime - new Date()) / 1000;
    if (diff >= 259200) {
      $("#current-inbox-timer-2").countdown({
        until: diff,
        compact: true,
        layout: "<b>{dn}d {hnn}h {mnn}m</b> {desc}",
        format: "dHM",
        description: "",
        // onExpiry: function(event) {
        //     var id= $(".counter-number-timeline-ideabox").data('mission-id')
        //     return $.ajax({
        //     type: 'POST',
        //     url:'/missions/' + id + '/update_ideabox_status',
        //     data: {from_timer: true},
        //     success: function(data) {}
        //   })
        // }
      });
    } else {
      $("#current-inbox-timer-2").countdown({
        until: diff,
        compact: true,
        layout: "<b>{hnn}h {mnn}m {snn}s</b> {desc}",
        format: "HMS",
        description: "",
        // onExpiry: function(event) {
        //   var id= $(".counter-number-timeline-ideabox").data('mission-id')
        //   return $.ajax({
        //   type: 'POST',
        //   url:'/missions/' + id + '/update_ideabox_status',
        //   data: {from_timer: true},
        //   success: function(data) {}
        //   })
        // }
      });
    }
  }

  $("input[id|='current-round-timer-ideabox']").each(function(index, element) {
    var newtime = new Date(parseFloat($(element).val()));
    var diff = (newtime - new Date()) / 1000;
  
    // Create a unique ID for each countdown timer based on the element index
    var timerId = "current-inbox-timer-" + element.dataset.missionId;
  
    if (diff >= 259200) {
      $("#" + timerId).countdown({
        until: diff,
        compact: true,
        layout: "<b>{dn}d {hnn}h {mnn}m</b> {desc}",
        format: "dHM",
        description: "",
      });
    } else {
      $("#" + timerId).countdown({
        until: diff,
        compact: true,
        layout: "<b>{hnn}h {mnn}m {snn}s</b> {desc}",
        format: "HMS",
        description: "",
      });
    }
  });

  var company_name, readURL, reply_form, user_first_name, user_last_name;
  readURL = function (input) {
    var reader;
    if (input.files && input.files[0]) {
      reader = new FileReader();
      reader.onload = function (e) {
        $("#img_prev").css("background-image", "url(" + e.target.result + ")");
      };
      reader.readAsDataURL(input.files[0]);
    }
  };

  $(".new_idea_tag_form").on("keyup keypress", function (e) {
    var keyCode = e.keyCode || e.which;
    if (keyCode === 13) {
      e.preventDefault();
      return false;
    }
  });

  $(".add-tag").on("click", function (e) {
    e.preventDefault();
    $(".add-tag-input").css("display", "block");
    $(".add-tag-input").focus();
    $(".add-tag-input").val("");
  });

  // $('.add-tag-input').blur(function(){
  //   $('.add-tag-input').css('display', 'none')
  // });

  $(".add-tag").on("click", function (e) {
    e.preventDefault();
    $(".add-dummy-tag-input").css("display", "block");
    $(".add-dummy-tag-input").val("");
  });

  var options = {
    url: function (phrase) {
      var disp = $(".easy-autocomplete-container").css("display");
      if (disp === "none") {
        $(".easy-autocomplete-container").css("display", "block");
      }
      return "/ideas/search?phrase=" + phrase + "&format=json";
    },

    getValue: "text",
    requestDelay: 100,
    list: {
      onClickEvent: function () {
        var value, id;
        value = $(".add-tag-input").getSelectedItemData().text;
        id = $(".add-tag-input").data("id");
        if (value != "") {
          $(".add-tag-input").val('')
          // $(".add-tag-input").css("display", "none");
          return $.ajax({
            type: "POST",
            url: "/ideas/add_idea_tag",
            data: { name: value, id: id },
            success: function (data) {},
          });
        } else {
          return Materialize.toast("Please add tag value", 2000);
        }
      },
      onHideListEvent: function () {
        if ($(".add-tag-input").val() === "") {
          // $(".add-tag-input").css("display", "none");
          $(".easy-autocomplete-container").css("display", "none");
        }
      },
    },
  };

  $(".add-tag-input").easyAutocomplete(options);

  var dummy_idea_options = {
    url: function (phrase) {
      return "/ideas/search?phrase=" + phrase + "&format=json";
    },

    getValue: "text",
    requestDelay: 500,
    list: {
      onClickEvent: function () {
        var value, ele;
        value = $(".add-dummy-tag-input").getSelectedItemData().text;
        if (value != "") {
          $(".add-dummy-tag-input").css("display", "none");
          ele = $("<button/>", {
            text: value + " x",
          });
          $("<input>")
            .attr({
              name: "tags[]",
              type: "hidden",
              value: value,
            })
            .appendTo(".new_idea_form");
          ele.css("margin-right", "5px");
          ele.addClass("dumb");
          $(".tag-dummy").append(ele);
        } else {
          return Materialize.toast("Please add tag value", 2000);
        }
      },
    },
  };

  $(".add-dummy-tag-input").easyAutocomplete(dummy_idea_options);

  // $(".add-tag-input").on("keyup", function (e) {
  //   var value, id;
  //   if (e.key === "Enter" || e.keyCode === 13) {
  //     e.preventDefault();
  //     value = $(".add-tag-input").val();
  //     id = $(this).data("id");
  //     if (value != "") {
  //       $(".add-tag-input").val('')
  //       // $(".add-tag-input").css("display", "none");
  //       return $.ajax({
  //         type: "POST",
  //         url: "/ideas/add_idea_tag",
  //         data: { name: value, id: id },
  //         success: function (data) {},
  //       });
  //     } else {
  //       return Materialize.toast("Please add tag value", 2000);
  //     }
  //   }
  // });

  $('.tags-submit-btn, .add-tag-input').on('click keyup', function(e) {
    var value, id;
    if (e.key === "Enter" || e.keyCode === 13 || this.classList.value === 'tags-submit-btn' ) {
      e.preventDefault();
      value = $(".add-tag-input").val();
      id = $(this).data("id");
      if (value != "") {
        $(".add-tag-input").val('')
        // $(".add-tag-input").css("display", "none");
        if (window.location.href.includes('profile/edit')){
          $(".add-tag-input").css("display", "block");
          return $.ajax({
            type: "POST",
            url: "/ideas/add_idea_tag",
            data: { name: value, id: id, add_as_skill: true, },
            success: function (data) {
              $(".add-tag-input").css("display", "block");
            },
          });
        }
        else if(window.location.href.includes('ideabox') && window.location.href.includes('settings')){
          $(".add-tag-input").css("display", "block");
          return $.ajax({
            type: "POST",
            url: "/ideas/add_idea_tag",
            data: { name: value, id: id, add_for_mission: true, },
            success: function (data) {
              $(".add-tag-input").css("display", "block");
            },
          });
        }
        else if(window.location.href.includes('user-management')){
          $(".add-tag-input").css("display", "block");
          return $.ajax({
            type: "POST",
            url: "/ideas/add_idea_tag",
            data: { name: value, id: id, add_for_user: true, },
            success: function (data) {
              $(".add-tag-input").css("display", "block");
            },
          });
        }
        else{
          return $.ajax({
            type: "POST",
            url: "/ideas/add_idea_tag",
            data: { name: value, id: id },
            success: function (data) {},
          });
        }
      } else {
        // return Materialize.toast("Please add tag value", 2000);
      }
    }
  });

  $(document).on("keydown", ".new_idea_form", function (event) {
    return event.key != "Enter";
  });

  $(".add-dummy-tag-input").on("keyup", function (e) {
    var value, ele;
    if (e.key === "Enter" || e.keyCode === 13) {
      e.preventDefault();
      value = $(".add-dummy-tag-input").val();
      if (value != "") {
        $(".add-dummy-tag-input").css("display", "none");
        ele = $("<button/>", {
          text: value + " x",
        });
        ele.css("margin-right", "5px");
        ele.addClass("dumb");
        $(".tag-dummy").append(ele);
        $("<input>")
          .attr({
            name: "tags[]",
            type: "hidden",
            value: value,
          })
          .appendTo(".new_idea_form");
      } else {
        return Materialize.toast("Please add tag value", 2000);
      }
    }
  });

  $(document).on("click", ".idea-tag-delete", function (e) {
    var id, idea_id;
    id = $(this).data("id");
    idea_id = $(this).data("idea-id");
    if (confirm("Are you sure want to delete?")) {
      return $.ajax({
        type: "DELETE",
        url: "/ideas/delete_idea_tag",
        data: { id: id, idea_id: idea_id },
        success: function (data) {},
        error: function (data) {},
      });
    }
  });

  $("#idea_image").change(function () {
    $("#img_prev").removeClass("hidden");
    readURL(this);
  });
  $(document).off("click", ".like-idea");
  $(document).on("click", ".like-idea", function () {
    var board_id, id, idea, inbox_id, mission, url;
    inbox_id = $("#mission_id").val();
    board_id = $("#board_id").val();
    id = $("#idea_id").val();
    if (!inbox_id && !id) {
      mission = this.getElementsByClassName("mission_id")[0];
      idea = this.getElementsByClassName("idea_id")[0];
      if (!!mission) {
        inbox_id = mission.value;
      }
      if (!!idea) {
        id = idea.value;
      }
    }
    if (!!inbox_id) {
      url = "/inbox/" + inbox_id + "/ideas/" + id + "/like_idea";
    } else if(board_id) {
      url = "/boards/" + board_id + "/ideas/" + id + "/like_idea";
    } else{
      url = '/ideas/like_funnel_idea';
    }
    return $.ajax({
      type: "POST",
      url: url,
      data: {},
      success: function (data) {},
    });
  });
  // $(document).on("focusin", ".temp-save", function(event) {
  //   var index = $(".temp-save").index(this);
  //   var value = localStorage.getItem('comment_' + index);
  //   if (value) {
  //     $(this).val(value);
  //   }
  // });
  
  // $(document).on("input", ".temp-save", function(event) {
  //   var index = $(".temp-save").index(this);
  //   var value = $(this).val();
  //   localStorage.setItem('comment_' + index, value);
  // });
  
  // $(document).on("input", ".temp-save", function (event) {
  //   var comment, reply_form;
  //   comment = $(".comment-input").val();
  //   reply_form = $(".card-border-reply");
  //   if (comment.length > 0 && reply_form.length === 0) {
  //     var comment = $(this).val();
  //     localStorage.setItem("comment", comment);
  //   } else {
  //     return $(".enable-add-button").addClass("disabled");
  //   }
  // });
  $(document).on("input", ".comment-input", function (event) {
    var comment, reply_form;
    comment = $(".comment-input").val();
    reply_form = $(".card-border-reply");
    if (comment.length > 0 && reply_form.length === 0) {
      return $(".enable-add-button").removeClass("disabled");
    } else {
      return $(".enable-add-button").addClass("disabled");
    }
  });
  
  $(document).on("change", ".idea_attachments", function (event) {
    return $("#form_idea_attachments").submit();
  });
  $(".submit-comment").off("click").on("click", function (e) {
    e.preventDefault();
    var comment = $.trim($("#comment").val());
  
    if (comment !== "") {
      // Submit the comment
      var comment_url =
        "/ideas/" +
        $("#idea_id").val() +
        "/edit";
      var title = $("#title").val();
      var user_id = $("#user_id").val();
  
      $.ajax({
        type: "POST",
        url: comment_url,
        data: {
          title: title,
          user_id: user_id,
          comment: comment,
        },
        success: function (data) {
          // Handle success
        },
        error: function (xhr, status, error) {
          // Handle error
        }
      });
    }
  });
  
  // $(".submit-comment").one("click", function (e) {
  //   var comment, comment_url, title, user_id;
  //   e.preventDefault();
  //   comment_url =
  //     "/inbox/" +
  //     $("#mission_id").val() +
  //     "/ideas/" +
  //     $("#idea_id").val() +
  //     "/edit";
  //   title = $("#title").val();
  //   user_id = $("#user_id").val();
  //   comment = $("#comment").val();
  //   return $.ajax({
  //     type: "POST",
  //     url: comment_url,
  //     data: {
  //       title: title,
  //       user_id: user_id,
  //       comment: comment,
  //     },
  //     success: function (data) {},
  //   });
  // });
  $(document).on("click", ".close-reply-form", function (event) {
    var reply_form;
    reply_form = $(".card-border-reply");
    return reply_form.remove();
  });
  $(document).on("input", ".reply-comment-input", function (event) {
    var comment;
    comment = $(".reply-comment-input").val();
    if (comment.length > 0 && reply_form.length === 0) {
      return $(".reply-enable-add-button").removeClass("disabled");
    } else {
      return $(".reply-enable-add-button").addClass("disabled");
    }
  });
  $(document).one("click", ".reply-comment-input", function (event) {
    var default_val;
    default_val = $(".mention_reply").data("parent-value");
    $(".reply-comment-input").val(default_val);
  });
  reply_form = $(".card-border-reply");
  if (reply_form.length > 0) {
    $(".enable-add-button").addClass("disabled");
  }
  $(document).on("click", ".reply-icon", function (e) {
    var comment_url;
    e.preventDefault();
    comment_url = $(this).data("url");
    return $.ajax({
      type: "GET",
      url: comment_url,
      dataType: "script",
      data: {},
    });
  });
  // $(document).on("click", ".reply-submit-comment", function (e) {
  //   var comment, comment_url, parent_id, title, user_id;
  //   e.preventDefault();
  //   comment_url =
  //     "/ideas/" +
  //     $("#idea_id").val() +
  //     "/edit";
  //   title = $("#title").val();
  //   user_id = $("#user_id").val();
  //   comment = $("#reply-comment").val();
  //   parent_id = $("#parent_id").val();
  //   return $.ajax({
  //     type: "POST",
  //     url: comment_url,
  //     data: {
  //       title: title,
  //       user_id: user_id,
  //       comment: comment,
  //       parent_id: parent_id,
  //     },
  //     success: function (data) {
  //       $(".card-border-reply").hide();
  //       frontend_ready();
  //     },
  //   });
  // });
  $(document).off("click", ".like-idea-all");
  $(document).on("click", ".like-idea-all", function () {
    var board_id, id, idea, inbox_id, mission, url;
    inbox_id = $("#mission_id").val();
    board_id = $("#board_id").val();
    id = $("#idea_id").val();
    if (!inbox_id && !id) {
      mission = this.getElementsByClassName("mission_id")[0];
      idea = this.getElementsByClassName("idea_id")[0];
      if (!!mission) {
        inbox_id = mission.value;
      }
      if (!!idea) {
        id = idea.value;
      }
    }
    if (!!inbox_id) {
      url = "/inbox/" + inbox_id + "/ideas/" + id + "/like_idea";
    } else if (board_id) {
      url = "/boards/" + board_id + "/ideas/" + id + "/like_idea";
    } else{
      url = "/ideas/like_funnel_idea";
    }
    return $.ajax({
      type: "POST",
      url: url,
      data: {id: id},
      success: function (data) {},
    });
  });

  $(".like-funnel-idea").off("click");
  $(document).on("click", ".like-funnel-idea", function () {
    var id, idea, funnel_id, funnel, url;
    funnel_id = $("#funnel_id").val();
    id = $("#idea_id").val();
    if (!funnel_id || !id) {
      funnel = this.getElementsByClassName("funnel_id")[0];
      idea = this.getElementsByClassName("idea_id")[0];
      if (!!funnel) {
        funnel_id = funnel.value;
      }
      if (!!idea) {
        id = idea.value;
      }
    }
    url = "/ideas/like_funnel_idea";
    return $.ajax({
      type: "POST",
      url: url,
      data: { funnel_id: funnel_id, id: id },
      success: function (data) {},
    });
  });

  
  $("#create-idea-admin").off("click");
  $("#create-idea-admin").on("click", function () {
    if (!$(".idea_title").val() || $(".idea_title").val().trim() == "") {
      $(".toast").remove();
      Materialize.toast("Idea Title can not be empty.", 2000);
      $(".idea_title").focus();
    } else {
      Materialize.toast("Idea has been saved.", 2000);
      return $(".new_idea_form")[0].submit();
    }
  });
  $(".profile-img").hover(
    function () {
      $(".profile-edit-pic").css("opacity", "0.6");
      return $(".modify").css("opacity", "1");
    },
    function () {
      $(".profile-edit-pic").css("opacity", "1");
      return $(".modify").css("opacity", "0");
    }
  );
  $(".project-profile-img").hover(
    function () {
      $(".profile-edit-pic").css("opacity", "0.6");
      return $(".modify").css("opacity", "1");
    },
    function () {
      $(".profile-edit-pic").css("opacity", "1");
      return $(".modify").css("opacity", "0");
    }
  );
  $(".dropdown-trigger").dropdown();
  tinymce.init(tinymce_settings);
  $(".tabs").tabs();
  $(".modal").modal();
  $(".collapsible").accordion();
  $(".asr-variables").find(".collapsible-header").off("keypress keydown keyup");
  $("form#new_user").submit(function () {
    var email, re;
    email = re = /[0-9a-zA-Z+]{1,100}@[0-1a-zA-Z]{2,20}.[0-1a-zA-Z]{2,20}/;
    if ($(".email_sign_in").val() === "" && $(".signin-pass").val() === "") {
      $(".email_sign_in").addClass("error");
      $(".signin-pass").addClass("error");
      if (!!$(".alert-user-session")[1]) {
        $(".alert-user-session")[1].innerHTML = "";
      }
      $(".email_sign_in").css("border-color", "red");
      $(".signin-pass").css("border-color", "red");
      if (!!document.getElementById("alert-user-session")) {
        document.getElementById("alert-user-session").innerHTML =
          "Email or Password cannot be empty";
      }
      return false;
    }
    if ($(".email_sign_in").val() === "") {
      $(".email_sign_in").addClass("error");
      if (!!$(".alert-user-session")[1]) {
        $(".alert-user-session")[1].innerHTML = "";
      }
      if (!!document.getElementById("alert-user-session")) {
        document.getElementById("alert-user-session").innerHTML =
          "Please fill in email";
      }
      $(".email_sign_in").css("border-color", "red");
      $(".signin-pass").css("border-color", "lightblue");
      return false;
    }
    if (!!$(".email_sign_in").val() && !$(".email_sign_in").val().match(re)) {
      $(".email_sign_in").addClass("error");
      if (!!$(".alert-user-session")[1]) {
        $(".alert-user-session")[1].innerHTML = "";
      }
      if (!!document.getElementById("alert-user-session")) {
        document.getElementById("alert-user-session").innerHTML =
          "Email validation message";
      }
      $(".email_sign_in").css("border-color", "red");
      $(".signin-pass").css("border-color", "lightblue");
      return false;
    }
    if ($(".signin-pass").val() === "") {
      $(".signin-pass").addClass("error");
      if (!!$(".alert-user-session")[1]) {
        $(".alert-user-session")[1].innerHTML = "";
      }
      $(".email_sign_in").css("border-color", "lightblue");
      $(".signin-pass").css("border-color", "red");
      if (!!document.getElementById("alert-user-session")) {
        document.getElementById("alert-user-session").innerHTML =
          "Please fill in Password";
      }
      return false;
    }
    return true;
  });
  $("form#reset-password-form").submit(function () {
    var re;
    if (
      $(".signin-pass-confirm").val() === "" &&
      $(".signin-pass").val() === ""
    ) {
      $(".signin-pass").addClass("error");
      $(".signin-pass-confirm").addClass("error");
      if ($(".alert-user-session")[1]) {
        $(".alert-user-session")[1].innerHTML = "";
      }
      $(".signin-pass").css("border-color", "red");
      $(".signin-pass-confirm").css("border-color", "red");
      if (!!document.getElementById("pass-check-msg")) {
        document.getElementById("pass-check-msg").innerHTML = "";
      }
      if (!!document.getElementById("alert-user-session")) {
        document.getElementById("alert-user-session").innerHTML =
          "Password or confirmation password cannot be empty";
      }
      return false;
    }
    if ($(".signin-pass").val() === "") {
      $(".signin-pass").addClass("error");
      if ($(".alert-user-session")[1]) {
        $(".alert-user-session")[1].innerHTML = "";
      }
      if (!!document.getElementById("alert-user-session")) {
        document.getElementById("alert-user-session").innerHTML =
          "Please fill in password";
      }
      if (!!document.getElementById("pass-check-msg")) {
        document.getElementById("pass-check-msg").innerHTML = "";
      }
      $(".signin-pass").css("border-color", "red");
      $(".signin-pass-confirm").css("border-color", "lightblue");
      return false;
    }
    if ($(".signin-pass-confirm").val() === "") {
      $(".signin-pass-confirm").addClass("error");
      if ($(".alert-user-session")[1]) {
        $(".alert-user-session")[1].innerHTML = "";
      }
      $(".signin-pass").css("border-color", "lightblue");
      $(".signin-pass-confirm").css("border-color", "red");
      if (!!document.getElementById("pass-check-msg")) {
        document.getElementById("pass-check-msg").innerHTML = "";
      }
      if (!!document.getElementById("alert-user-session")) {
        document.getElementById("alert-user-session").innerHTML =
          "Please fill in confirmation password";
      }
      return false;
    }
    re = /[A-Za-z0-9]*\W[A-Za-z0-9]*/;
    if (
      !$(".pass_field").val().match(re) ||
      $(".pass_field").val().length < 8
    ) {
      $(".signin-pass-confirm").addClass("error");
      if ($(".alert-user-session")[1]) {
        $(".alert-user-session")[1].innerHTML = "";
      }
      $(".signin-pass").css("border-color", "lightblue");
      $(".signin-pass-confirm").css("border-color", "red");
      if (!!document.getElementById("alert-user-session")) {
        document.getElementById("alert-user-session").innerHTML = "";
      }
      if (!!document.getElementById("pass-check-msg")) {
        document.getElementById("pass-check-msg").innerHTML =
          "Password must be a minimum of 8 characters and contain at least one special character";
      }
      return false;
    }
    if ($(".signin-pass-confirm").val() !== $(".signin-pass").val()) {
      $(".signin-pass-confirm").addClass("error");
      $(".signin-pass").addClass("error");
      if ($(".alert-user-session")[1]) {
        $(".alert-user-session")[1].innerHTML = "";
      }
      $(".signin-pass").css("border-color", "red");
      $(".signin-pass-confirm").css("border-color", "red");
      if (!!document.getElementById("pass-check-msg")) {
        document.getElementById("pass-check-msg").innerHTML = "";
      }
      if (!!document.getElementById("alert-user-session")) {
        document.getElementById("alert-user-session").innerHTML =
          "Password and confirmation password did not match";
      }
      return false;
    }
    return true;
  });
  $("a#invitation-link").on("click", function () {
    return $("#invitation-tab").click();
  });
  $(".collapsible").collapsible({
    accordion: true,
    onOpen: function (el) {
      return $("#visibility").text("visibility_off");
    },
    onClose: function (el) {
      return $("#visibility").text("visibility");
    },
  });
  if ($("#scroll-to-bottom").length > 0) {
    window.location.hash = "#scroll-to-bottom";
  }
  $(".learn-more-btn").on("click", function () {
    $(".main").moveDown();
  });
  $("ul.tabs").tabs();
  $("select").material_select();
  if ($(window).width() < 600) {
    $(".button-collapse").sideNav({
      menuWidth: 250,
    });
  } else {
    $(".button-collapse").sideNav({
      menuWidth: 300,
    });
    ({
      items: 2,
      navigation: true,
      slideSpeed: 1000,
      scrollPerPage: true,
      navigationText: [
        "<i class='icon-chevron-left icon-white material-icons'>keyboard_arrow_left</i>",
        "<i class='icon-chevron-right icon-white material-icons'>keyboard_arrow_right</i>",
      ],
    });
  }
  $(".delete-text").keyup(function () {
    if ($(".delete-text").val().toUpperCase() === "DELETE") {
      $(".delete-mission-btn")[0].style.visibility = "";
    } else {
      $(".delete-mission-btn")[0].style.visibility = "hidden";
    }
  });
  init_date_picker();
  init_time_picker();
  show_notice_toast();
  disable_default_form_submit();
  // if (!!pusher) {
  //   window.channel_notification = pusher.subscribe("notification_channel");
  //   window.channel_notification.bind("notification_event", function (data) {
  //     var current_user_id, current_user_included_in_team;
  //     console.log("notification bell updating");
  //     current_user_id = parseInt($(".user_id").val());
  //     current_user_included_in_team = data.team_users.includes(current_user_id);
  //     if (current_user_included_in_team) {
  //       if (
  //         $(".notification-badge-bell").html().trim() !==
  //         data.live_notifications_count.toString().trim()
  //       ) {
  //         $(".notification-badge-bell").html(data.live_notifications_count);
  //         $(".notification-list").prepend(data.notification_li);
  //         $(".notification-badge-bell").show();
  //         $("#notifications_id").val(data.new_notification_ids.join(" "));
  //         return console.log("notification bell updated");
  //       }
  //     }
  //   });
  // }
  $("a[data-activates='notifications']").on("click", function () {
    $(".notification-badge-bell").hide();
    $("#notifications").css("opacity", "1");
    return $.ajax({
      type: "GET",
      url: "/notifications/live_notifications_seen",
      data: {
        notification_ids: $("#notifications_id").val(),
      },
      success: function (data) {},
    });
  });
  $(".progression-menu").on("click", function () {
    var path;
    path = $(this).data("url");
    return $.ajax({
      type: "GET",
      url: path,
      success: function (response) {
        $("#agent-progression-stat")
          .find(".progression-details")
          .html(response);
        $("#agent-progression-stat").find($("ul.tabs")).tabs();
        $("#agent-progression-stat").modal("open");
        $(".side-nav").sideNav("hide");
        return $("#all-missions-xp").click();
      },
    });
  });
  $(".mission-create-show-advance-fields").on("click", function () {
    $(".mission-create-adv").hide();
    return $(".mission-create-advance-fields").removeClass("hide");
  });
  $(".mission-create-hide-advance-fields").on("click", function () {
    $(".mission-create-advance-fields").addClass("hide");
    $(".mission-create-adv").show();
    return mission_image();
  });
  $(".mission-edit-show-advance-fields").on("click", function () {
    $(".mission-edit-adv").hide();
    $(".mission-edit-advance-fields").removeClass("hide");
    return mission_image();
  });
  $(".mission-edit-hide-advance-fields").on("click", function () {
    $(".mission-edit-advance-fields").addClass("hide");
    return $(".mission-edit-adv").show();
  });
  $(".check-email").on("change", function () {
    var email;
    email = $(".check-email").val();
    return $.ajax({
      type: "POST",
      url: "/check_email",
      data: {
        email: email,
      },
      success: function (data) {},
    });
  });
  $(".check-pass").on("change", function () {
    var password, re;
    password = $(".check-pass").val();
    re = /[A-Za-z0-9]*\W[A-Za-z0-9]*/;
    if (
      !$(".pass_field").val().match(re) ||
      $(".pass_field").val().length < 8
    ) {
      if (!!document.getElementById("error-msg")) {
        document.getElementById("error-msg").innerHTML = "";
      }
      if (!!document.getElementById("alert-user-session")) {
        document.getElementById("alert-user-session").innerHTML = "";
      }
      if (!!document.getElementById("pass-check-msg")) {
        return (document.getElementById("pass-check-msg").innerHTML =
          "Password must be a minimum of 8 characters and contain at least one special character");
      }
    } else {
      if (!!document.getElementById("pass-check-msg")) {
        return (document.getElementById("pass-check-msg").innerHTML = "");
      }
    }
  });
  user_first_name = document.getElementById("user_first_name");
  if (!!user_first_name) {
    user_first_name.onkeypress = function (evt) {
      var charCode;
      charCode = evt.which ? evt.which : event.keyCode;
      if (
        !(
          (charCode >= 65 && charCode <= 90) ||
          (charCode >= 97 && charCode <= 122) ||
          charCode === 45 ||
          charCode === 32
        )
      ) {
        return false;
      }
      return true;
    };
  }
  user_last_name = document.getElementById("user_last_name");
  if (!!user_last_name) {
    user_last_name.onkeypress = function (evt) {
      var charCode;
      charCode = evt.which ? evt.which : event.keyCode;
      if (
        !(
          (charCode >= 65 && charCode <= 90) ||
          (charCode >= 97 && charCode <= 122) ||
          charCode === 45 ||
          charCode === 32
        )
      ) {
        return false;
      }
      return true;
    };
  }
  company_name = document.getElementById("company_name");
  if (!!company_name) {
    company_name.onkeypress = function (evt) {
      if ($("#company_name").val().length > 39) {
        return false;
      }
      return true;
    };
  }
  $(".sign-up-link").on("click", function () {
    return (window.location.href = window.location.origin + "/signup_company");
  });
  $(".image-upload").hover(
    function () {
      $(".mission-edit-mission-pic").css("opacity", "0.6");
      return $(".add-opacity").css("opacity", "1");
    },
    function () {
      $(".mission-edit-mission-pic").css("opacity", "1");
      return $(".add-opacity").css("opacity", "0");
    }
  );
  return mission_image();
};

$.ajaxSetup({
  global: true,
});

$(document).ajaxStart(function () {
  setTimeout(function () {
    return $(".preloader-wrapper").addClass("active");
  }, 500);
});

$(document).ajaxComplete(function () {
  $(".preloader-wrapper").removeClass("active");
});

$(".custom-tabs-mobile").each(function () {
  this.style.setProperty("width", "100%", "important");
});

$(".mission-box-mobile").each(function () {
  this.style.setProperty("min-height", "300px", "important");
});

$("div#livechat-compact-container").each(function () {
  this.style.setProperty("bottom", "40px", "important");
});

$(document).ready(frontend_ready);

$(document).on("ajax:complete", frontend_ready);
