window.add_comments = function() {
  $('#add-comment').on('click', function() {
    var element;
    element = $('#comment');
    if (element.val()) {
      return $.ajax({
        type: 'POST',
        url: element.data("url"),
        data: {
          comment: {
            title: '',
            comment: element.val(),
            user_id: element.data("user-id")
          }
        },
        success: function(data) {
          $('.comment_container').prepend(data);
          $('#comment').off('keypress');
          $('#add-comment').off('click');
          $('#comment').val('');
          return $(document).trigger('ajax:complete');
        }
      });
    }
  });
  $(document).off('click', '.add-comment');
  return $(document).on('click', '.add-comment', function() {
    var element;
    element = $('#comment');
    if (element.val()) {
      return $.ajax({
        type: 'POST',
        url: element.data("url"),
        data: {
          comment: {
            title: '',
            comment: element.val(),
            user_id: element.data("user-id")
          }
        },
        success: function(data) {
          $('.comment_container').prepend(data);
          $('#comment').off('keypress');
          $('.add-comment').off('click');
          return $('#comment').val('');
        }
      });
    }
  });
};

$(document).ready(add_comments);

$(document).on('ajax:complete', add_comments);
